import {
    Button,
    Checkbox,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
    Text,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";
import Cookies from "js-cookie";
import React from "react";
import { AppConfigContext } from "src/context";
import { Link } from "../Link";

const TERMS_COOKIE_NAME = "terms-accepted-date";

export const TermsModal = () => {
    const { termsOfUseUrl } = React.useContext(AppConfigContext);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [accepted, setAccepted] = React.useState(!!Cookies.get(TERMS_COOKIE_NAME));

    const onEnter = () => {
        Cookies.set(TERMS_COOKIE_NAME, new Date().toUTCString(), { expires: 3650 });
        onClose();
    };

    React.useEffect(() => {
        !accepted && onOpen();
    }, [accepted]);

    return (
        <Modal
            size={"6xl"}
            closeOnOverlayClick={false}
            isOpen={isOpen}
            onClose={onClose}
            autoFocus={false}
            scrollBehavior={"inside"}
            isCentered
        >
            <ModalOverlay />
            <ModalContent mt={{ base: 0, sm: 8, md: 16 }}>
                <ModalHeader>Warning</ModalHeader>
                <ModalBody pt={0}>
                    <Stack>
                        <Text>
                            No representation or warranty is made concerning any aspect of the HedgeFarm Protocol,
                            including its suitability, quality, availability, accessibility, accuracy or safety. As more
                            fully explained in the Terms of Use (available{" "}
                            <Link to={termsOfUseUrl} target={"_blank"}>
                                here
                            </Link>
                            ), your access to and use of the HedgeFarm Protocol through this Interface is entirely at
                            your own risk and could lead to substantial losses, for which you take full responsibility.
                        </Text>
                        <Text>
                            This interface is not available to residents of Belarus, the Central African Republic, the
                            Democratic Republic of Congo, the Democratic People’s Republic of Korea, the Crimea region
                            of Ukraine, Cuba, Iran, Libya, the People’s Republic of China, the Russian Federation,
                            Somalia, Sudan, South Sudan, Syria, the United States of America, Yemen, and Zimbabwe or any
                            other jurisdiction in which accessing or using the HedgeFarm Protocol is prohibited
                            (“Prohibited Jurisdictions”). In using this Interface, you confirm that you are not located
                            in, incorporated or otherwise established in, or resident of, a Prohibited Jurisdiction.
                        </Text>
                    </Stack>
                </ModalBody>
                <ModalFooter>
                    <VStack spacing={4} w={"100%"}>
                        <Checkbox isChecked={accepted} onChange={({ target }) => setAccepted(target.checked)}>
                            I confirm that I have read, understand and accept the{" "}
                            <Link to={termsOfUseUrl} target={"_blank"}>
                                Terms of Use
                            </Link>
                        </Checkbox>
                        <Button onClick={onEnter} isDisabled={!accepted}>
                            Enter
                        </Button>
                    </VStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
