import { Token, Strategy, StrategyAbi, TVD, AccountBalance, StrategyClient } from "../types";
import { BigDecimal, BIG_MAX_UINT256, safeParseBigDecimal } from "../../utils/bigDecimal";
import { tokenUnitConverter, TokenUnitConverter } from "../../utils/tokenUnitConverter";
import coingeckoApiService from "../../rest/coingecko";
import { promiseFromResult } from "../../utils/data";
import { Erc20TokenAbi } from "../generated-types/Erc20TokenAbi";
import { Maybe } from "true-myth";
import { ContractTransaction } from "ethers";

const PRICE_PER_SHARE_CONVERTER_DECIMALS = 18;

export class Alpha2SmartFarmooorClient implements StrategyClient<Strategy.Alpha2SmartFarmooor> {
    private depositTokenUnitConverter: Maybe<TokenUnitConverter>;
    private iouTokenUnitConverter: Maybe<TokenUnitConverter>;

    constructor(
        public contract: StrategyAbi<Strategy.Alpha2SmartFarmooor>,
        private depositTokenContract: Erc20TokenAbi,
        private contractAddress: string,
    ) {
        this.depositTokenUnitConverter = Maybe.nothing();
        this.iouTokenUnitConverter = Maybe.nothing();
        this.getDepositTokenUnitConverter.bind(this);
    }

    private getIOUTokenUnitConverter = async (): Promise<TokenUnitConverter> =>
        this.iouTokenUnitConverter.match({
            Nothing: async () => {
                const iouToken = await this.getIOUToken();
                const converter = tokenUnitConverter(iouToken.decimals);
                this.iouTokenUnitConverter = Maybe.just(converter);
                return converter;
            },
            Just: converter => Promise.resolve(converter),
        });

    private getDepositTokenUnitConverter = async (): Promise<TokenUnitConverter> =>
        this.depositTokenUnitConverter.match({
            Nothing: async () => {
                const depositToken = await this.getDepositToken();
                const converter = tokenUnitConverter(depositToken.decimals);
                this.depositTokenUnitConverter = Maybe.just(converter);
                return converter;
            },
            Just: converter => Promise.resolve(converter),
        });

    getCap = async (): Promise<BigDecimal> => {
        const cap = await this.contract.cap();
        const converter = await this.getDepositTokenUnitConverter();

        return promiseFromResult(converter.parse(cap));
    };

    getDepositToken = async (): Promise<Token> => {
        const symbol = await this.depositTokenContract.symbol();
        const decimals = await this.depositTokenContract.decimals();

        return { symbol, decimals };
    };

    getIOUToken = async (): Promise<Token> => {
        const symbol = await this.contract.symbol();
        const decimals = await this.contract.decimals();

        return { symbol, decimals };
    };

    getIsPaused = async (): Promise<boolean> => {
        return this.contract.paused();
    };

    getTotalBalance = async (): Promise<BigDecimal> => {
        const totalBalance = await this.contract.callStatic.getModulesBalance();
        const converter = await this.getDepositTokenUnitConverter();

        return promiseFromResult(converter.parse(totalBalance));
    };

    getTotalValueDeposited = async (): Promise<TVD> => {
        const totalSupply = await this.contract.totalSupply();
        const pricePerShare = await this.contract.callStatic.pricePerShare();

        const converter = await this.getDepositTokenUnitConverter();
        const pricePerShareConverter = tokenUnitConverter(PRICE_PER_SHARE_CONVERTER_DECIMALS);

        const tvd = await promiseFromResult(
            safeParseBigDecimal(totalSupply)
                .flatMap(ts => pricePerShareConverter.parse(pricePerShare).map(pps => ts.mul(pps)))
                .flatMap(tvdRaw => converter.parse(tvdRaw)),
        );

        const {
            market_data: { current_price },
        } = await coingeckoApiService.getCoin("binance-usd");

        return promiseFromResult(
            safeParseBigDecimal(current_price.usd).map<TVD>(price => ({
                tvd,
                usdEquivalent: tvd.mul(price),
            })),
        );
    };

    getAllowance = async (account: string): Promise<BigDecimal> => {
        const allowance = await this.depositTokenContract.allowance(account, this.contractAddress);
        const converter = await this.getDepositTokenUnitConverter();

        return promiseFromResult(converter.parse(allowance));
    };

    getAccountBalance = async (account: string): Promise<AccountBalance> => {
        const balance = await this.contract.balanceOf(account);
        const pricePerShare = await this.contract.callStatic.pricePerShare();

        const converter = await this.getDepositTokenUnitConverter();
        const pricePerShareConverter = tokenUnitConverter(PRICE_PER_SHARE_CONVERTER_DECIMALS);

        const shares = await promiseFromResult(converter.parse(balance));

        return promiseFromResult(
            safeParseBigDecimal(pricePerShare)
                .flatMap(price => pricePerShareConverter.parse(price))
                .map<AccountBalance>(price => ({
                    shares,
                    amount: shares.mul(price),
                })),
        );
    };

    getDepositTokenBalance = async (account: string): Promise<BigDecimal> => {
        const balance = await this.depositTokenContract.balanceOf(account);
        const converter = await this.getDepositTokenUnitConverter();

        return promiseFromResult(converter.parse(balance));
    };

    approveSpend = async (account: string, amount?: BigDecimal): Promise<ContractTransaction> => {
        const converter = await this.getDepositTokenUnitConverter();
        const approveAmount = amount ? await promiseFromResult(converter.format(amount)) : BIG_MAX_UINT256.toFixed();

        return this.depositTokenContract.approve(this.contractAddress, approveAmount, { from: account });
    };

    deposit = async (account: string, amount: BigDecimal): Promise<ContractTransaction> => {
        const converter = await this.getDepositTokenUnitConverter();
        const depositAmount = await promiseFromResult(converter.format(amount));

        return this.contract.deposit(depositAmount, { from: account, gasLimit: 2500000 });
    };

    withdraw = async (account: string, amount: BigDecimal): Promise<ContractTransaction> => {
        const converter = await this.getDepositTokenUnitConverter();
        const withdrawAmount = await promiseFromResult(converter.format(amount));

        return this.contract.withdraw(withdrawAmount, { from: account });
    };
}
