import React from "react";
import { Flex, HStack, Text } from "@chakra-ui/react";
import { Icon, IconName } from "src/uikit/icon";
import { AppConfigContext } from "src/context";
import { Link } from "../Link";

export const Footer = () => {
    const { termsOfUseUrl } = React.useContext(AppConfigContext);

    const renderIconLink = (name: IconName, to: string) => (
        <Link display={"flex"} to={to} target="_blank">
            <Icon name={name} fill={"gray.500"} />
        </Link>
    );

    return (
        <Flex
            as={"footer"}
            minH={"10rem"}
            alignItems={"center"}
            justifyContent={"space-between"}
            flexDirection={{ base: "column", md: "row" }}
            py={{ base: "2.1rem", md: 0 }}
        >
            <Text color={"gray.500"} py={"1.1rem"}>
                &copy; {new Date().getFullYear()} HedgeFarm
            </Text>

            <HStack spacing={{ base: 0, md: 16 }} display={{ base: "contents", md: "flex" }}>
                <HStack spacing={6} py={"1.1rem"}>
                    <Link to={termsOfUseUrl}>Terms of Use</Link>
                    <Link to={"https://docs.hedgefarm.finance/"} target={"_blank"}>
                        Docs
                    </Link>
                </HStack>
                <HStack spacing={5} py={"1.1rem"}>
                    {renderIconLink("generic.discord", "https://discord.com/invite/b57NTqH7SG")}
                    {renderIconLink("generic.twitter", "https://twitter.com/hedge_farm")}
                    {renderIconLink("generic.github", "https://github.com/HedgeFarm")}
                </HStack>
            </HStack>
        </Flex>
    );
};
