import { Image, ImageProps } from "@chakra-ui/react";
import React from "react";

import Shield0 from "../assets/Shield-0.png";
import Shield05 from "../assets/Shield-0.5.png";
import Shield1 from "../assets/Shield-1.png";
import Shield15 from "../assets/Shield-1.5.png";
import Shield2 from "../assets/Shield-2.png";
import Shield25 from "../assets/Shield-2.5.png";
import Shield3 from "../assets/Shield-3.png";

const shieldMap = {
    0: Shield0,
    0.5: Shield05,
    1: Shield1,
    1.5: Shield15,
    2: Shield2,
    2.5: Shield25,
    3: Shield3,
};

export type ShieldValue = keyof typeof shieldMap;

interface Props extends ImageProps {
    value: ShieldValue;
}

export const Shield = ({ value, ...props }: Props) => <Image src={shieldMap[value]} {...props} />;
