import { Center, Flex, Spinner, TableContainer, Text } from "@chakra-ui/react";
import styled from "@emotion/styled";
import React from "react";
import { PerformanceData } from "src/rest/hedgeFarm";
import { ThemeColor } from "src/theme/palette";
import { Card, CardProps, Notice, TooltipLabel } from "src/uikit";
import { Stats } from "src/uikit/Stats";
import { Heading } from "src/uikit/typography";
import { formatNumber, FormatNumberOptions } from "src/utils/format";
import { Maybe } from "true-myth";
import { ChartAlpha1 } from "./components/ChartAlpha1";
import { ChartAlpha2 } from "./components/ChartAlpha2";
import { EpochHistoryTable } from "./components/EpochHistoryTable";

const Statistics = styled(Stats)`
    & > div:not(:first-of-type) {
        padding-left: 2.6rem;
    }
`;

type Chart = typeof ChartAlpha1 | typeof ChartAlpha2;

interface Props extends CardProps {
    bodyText: string;
    data: Maybe<PerformanceData>;
    Chart: Chart;
    investmentValueFormatterOptions?: FormatNumberOptions;
    returnsValueFormatterOptions?: FormatNumberOptions;
}

export const Performance = ({
    bodyText,
    data,
    Chart,
    investmentValueFormatterOptions = { prefix: "$" },
    returnsValueFormatterOptions = { prefix: "$" },
    ...props
}: Props) => {
    return (
        <Card {...props}>
            <Flex direction={"column"}>
                <Heading as={"h4"} color={ThemeColor.ACTION_PRIMARY}>
                    Performance
                </Heading>
                {data
                    .map(({ historicalPerformance, epochPerformance }) => (
                        <>
                            <Text mt={4}>{bodyText}</Text>
                            <Statistics
                                mt={6}
                                stats={[
                                    {
                                        label: "Investment",
                                        value: formatNumber(
                                            historicalPerformance.investmentAmount,
                                            investmentValueFormatterOptions,
                                        ),
                                        tooltipLabel: (
                                            <TooltipLabel>
                                                Initial amount invested at the start of the strategy.
                                            </TooltipLabel>
                                        ),
                                        icon: "generic.cash",
                                    },
                                    {
                                        label: "Returns",
                                        value: formatNumber(historicalPerformance.yield, returnsValueFormatterOptions),
                                        tooltipLabel: (
                                            <TooltipLabel>Money made or lost over the investment period.</TooltipLabel>
                                        ),
                                        icon: "generic.yield",
                                        borderColor: ThemeColor.LINES,
                                    },
                                    {
                                        label: "APY",
                                        value: formatNumber(historicalPerformance.apy, { suffix: "%" }),
                                        tooltipLabel: (
                                            <TooltipLabel>Historical APY since the start of the strategy.</TooltipLabel>
                                        ),
                                        icon: "generic.calendar",
                                        borderColor: ThemeColor.LINES,
                                    },
                                ]}
                            />
                            {<Chart mt={6} data={historicalPerformance} />}
                            {epochPerformance && (
                                <>
                                    <Heading as={"h5"} mt={8}>
                                        Epoch History
                                    </Heading>
                                    {epochPerformance.length > 0 ? (
                                        <TableContainer mt={2}>
                                            <EpochHistoryTable variant={"info"} data={epochPerformance} />
                                        </TableContainer>
                                    ) : (
                                        <Notice color={ThemeColor.ACTION_PRIMARY} icon={"generic.info"} mt={4}>
                                            No epochs are completed yet.
                                        </Notice>
                                    )}
                                </>
                            )}
                        </>
                    ))
                    .unwrapOr(
                        <Center h={"57.6rem"}>
                            <Spinner />
                        </Center>,
                    )}
            </Flex>
        </Card>
    );
};
