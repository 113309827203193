import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";
import { ThemeColor } from "src/theme/palette";
import { Icon, IconName } from "./icon";

interface Props extends BoxProps {
    icon: IconName;
    iconColor?: string;
    iconSize?: BoxProps["w"];
    boxColor?: string;
    boxSize?: BoxProps["w"];
}

export const IconBox = ({
    icon,
    iconColor = ThemeColor.CONTENT,
    iconSize = "2rem",
    boxColor = ThemeColor.BACKGROUND,
    boxSize = "3.2rem",
    ...props
}: Props) => (
    <Box
        display={"flex"}
        borderRadius={"full"}
        justifyContent={"center"}
        alignItems={"center"}
        bg={boxColor}
        w={boxSize}
        h={boxSize}
        {...props}
    >
        <Icon name={icon} fill={iconColor} w={iconSize} h={iconSize} />
    </Box>
);
