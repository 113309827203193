import { HStack, StackProps, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { ThemeColor } from "src/theme/palette";
import { Icon, IconName } from "src/uikit/icon";

interface Props extends StackProps {
    label: string;
    value: string;
    icons: IconName[];
}

export const Parameter = ({ label, value, icons, ...props }: Props) => (
    <VStack
        py={"1.6rem"}
        px={{ base: "0.8rem", md: "1.6rem" }}
        minWidth={{ base: "none", md: "11.15rem" }}
        spacing={1}
        {...props}
    >
        <Text fontSize={"1.2rem"}>{label}</Text>
        <Text fontWeight={"semibold"}>{value}</Text>
        <HStack display={{ base: "none", md: "flex" }}>
            {icons.map(name => (
                <Icon key={name} name={name} fill={ThemeColor.ACTION_PRIMARY} />
            ))}
        </HStack>
    </VStack>
);
