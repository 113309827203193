import React from "react";
import { Footer, Header, SunsetNotice, Link } from "./components";
import { Box, Container, Text, useColorMode } from "@chakra-ui/react";
import { Strategy } from "./contracts";
import { chains } from "./wagmi";
import { darkTheme, lightTheme, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { Alpha1Page, Alpha2Page, TermsPage } from "./pages";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Notice } from "./uikit";
import { ThemeColor } from "./theme/palette";

export function App() {
    const { colorMode } = useColorMode();
    const { pathname } = useLocation();

    const getStrategyPage = (strategy: Strategy) => {
        switch (strategy) {
            case Strategy.Alpha2SmartFarmooor:
                return (
                    <Alpha2Page<Strategy.Alpha2SmartFarmooor>
                        strategy={Strategy.Alpha2SmartFarmooor}
                        strategySymbol={"BTCB"}
                    />
                );
            case Strategy.Alpha1TheCPPIooorV1:
                return (
                    <Alpha1Page<Strategy.Alpha1TheCPPIooorV1>
                        strategy={Strategy.Alpha1TheCPPIooorV1}
                        strategySymbol={"USDC"}
                        allowlistEnabled={true}
                        notice={
                            <Notice icon={"generic.info"} color={ThemeColor.ACTION_WARNING} py={4}>
                                <Text>
                                    V1 is in withdrawal mode only. You can access the V2 version{" "}
                                    <Link to={"/"}>here</Link>.
                                </Text>
                            </Notice>
                        }
                    />
                );
            case Strategy.Alpha1TheCPPIooorV2:
            default:
                return (
                    <Alpha1Page<Strategy.Alpha1TheCPPIooorV2>
                        strategy={Strategy.Alpha1TheCPPIooorV2}
                        strategySymbol={"USDC"}
                        notice={
                            <Notice icon={"generic.info"} color={ThemeColor.ACTION_PRIMARY} py={4}>
                                <Text>
                                    V2 is the new version, if you have funds in V1 please withdraw them using{" "}
                                    <Link to={"/the-cppiooor-v1"}>this link</Link>.
                                </Text>
                            </Notice>
                        }
                    />
                );
        }
    };

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <RainbowKitProvider chains={chains} theme={colorMode === "light" ? lightTheme() : darkTheme()}>
            <SunsetNotice />
            <Container
                display={"flex"}
                flexDirection={"column"}
                minH={"100vh"}
                maxW={"container.xl"}
                paddingX={{ base: "1.8rem", sm: "2.8rem", md: "3.8rem", lg: "2rem", xl: "4.8rem" }}
            >
                <Header />
                <Box as={"main"} flex={1}>
                    <Routes>
                        <Route index element={getStrategyPage(Strategy.Alpha1TheCPPIooorV2)} />
                        <Route path={"the-cppiooor-v1"} element={getStrategyPage(Strategy.Alpha1TheCPPIooorV1)} />
                        <Route path={"smart-farmooor"} element={getStrategyPage(Strategy.Alpha2SmartFarmooor)} />
                        <Route path={"terms-of-use"} element={<TermsPage />} />
                        <Route path={"*"} element={<Navigate to="/" replace />} />
                    </Routes>
                </Box>
                <Footer />
            </Container>
        </RainbowKitProvider>
    );
}
