import React, { useCallback } from "react";
import { Logo } from "../Logo";
import { Maybe } from "true-myth";
import {
    Button,
    Flex,
    HStack,
    Text,
    useColorMode,
    Menu,
    MenuList,
    MenuButton,
    MenuItem,
    useDisclosure,
    Box,
    MenuGroup,
} from "@chakra-ui/react";
import { ThemeColor } from "src/theme/palette";
import { Icon } from "src/uikit/icon";
import { useAccountModal, useChainModal, useConnectModal } from "@rainbow-me/rainbowkit";
import { useAccount, useNetwork } from "wagmi";
import { formatAddress } from "src/utils/format";
import { assets, AssetSymbol } from "src/theme";
import { Link } from "../Link";
import { Asset } from "../../uikit";
import { To, useNavigate } from "react-router-dom";

export const Header = () => {
    const { colorMode, toggleColorMode } = useColorMode();
    const { address, isConnecting } = useAccount();
    const { openConnectModal } = useConnectModal();
    const { openAccountModal } = useAccountModal();
    const { openChainModal } = useChainModal();
    const { chain } = useNetwork();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const navigate = useNavigate();

    const asset = Maybe.of(assets[chain?.nativeCurrency?.symbol as AssetSymbol]);

    const menuNavigate = useCallback(
        (to: To) => {
            navigate(to);
            onClose();
        },
        [navigate, onClose],
    );

    return (
        <Flex as={"header"} h={"8.8rem"} alignItems={"center"} justifyContent={"space-between"}>
            <HStack spacing={{ base: 5, lg: 14 }}>
                <Logo />
                <HStack as={"nav"} spacing={6}>
                    <Box mt={2} pb={2} onMouseEnter={onOpen} onMouseLeave={onClose}>
                        <Menu isOpen={isOpen}>
                            <MenuButton as={Button} variant={"outline"}>
                                Vaults
                            </MenuButton>
                            <MenuList>
                                <MenuGroup title={"Alpha #1"}>
                                    <MenuItem
                                        icon={<Asset size={"menu"} symbol={"USDC"} />}
                                        onClick={() => menuNavigate("/the-cppiooor-v1")}
                                    >
                                        The CPPIooor - USDC
                                    </MenuItem>
                                </MenuGroup>
                                <MenuGroup title={"Alpha #2"}>
                                    <MenuItem
                                        icon={<Asset size={"menu"} symbol={"BTCB"} />}
                                        onClick={() => menuNavigate("/smart-farmooor")}
                                    >
                                        The Smart Farmooor - BTC.b
                                    </MenuItem>
                                </MenuGroup>
                            </MenuList>
                        </Menu>
                    </Box>
                    <Link to={"https://docs.hedgefarm.finance/"} target={"_blank"}>
                        Docs
                    </Link>
                </HStack>
            </HStack>

            <HStack spacing={4}>
                <Button
                    display={{ base: "none", sm: "inline-flex" }}
                    variant={"outline-gray"}
                    borderRadius={"full"}
                    leftIcon={
                        <Icon
                            name={`generic.${colorMode === "light" ? "lightMode" : "darkMode"}`}
                            w={"2rem"}
                            h={"2rem"}
                        />
                    }
                    iconSpacing={0}
                    paddingInline={0}
                    onClick={toggleColorMode}
                />
                {chain && openChainModal && (
                    <Button
                        display={{ base: chain.unsupported ? "inline-flex" : "none", md: "inline-flex" }}
                        variant={"outline-gray"}
                        onClick={openChainModal}
                        {...(chain.unsupported && {
                            color: ThemeColor.ACTION_ERROR,
                            borderColor: ThemeColor.ACTION_ERROR,
                        })}
                    >
                        {chain.unsupported ? (
                            "Unsupported network"
                        ) : (
                            <HStack spacing={2}>
                                {asset.mapOr(<></>, ({ icon, color }) => (
                                    <Icon name={icon} fill={color} w={"2rem"} h={"2.rem"} />
                                ))}
                                <Text>{chain.name}</Text>
                            </HStack>
                        )}
                    </Button>
                )}
                {address && openAccountModal && (
                    <Button variant={"outline-gray"} onClick={openAccountModal}>
                        {formatAddress(address)}
                    </Button>
                )}
                {openConnectModal && (
                    <Button variant={"outline"} onClick={openConnectModal} isLoading={isConnecting}>
                        Connect Wallet
                    </Button>
                )}
            </HStack>
        </Flex>
    );
};
