import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { ThemeColor } from "../../theme/palette";
import { Link } from "../Link";
import { getAlphaColor } from "../../theme/utils";

export const SunsetNotice = () => {
    return (
        <Flex
            color={ThemeColor.ACTION_WARNING}
            bg={getAlphaColor(ThemeColor.ACTION_WARNING as string, 0.1)}
            px={"0rem"}
            py={"0.8rem"}
            alignItems={"center"}
            sx={{
                a: {
                    color: getAlphaColor(ThemeColor.ACTION_WARNING as string, 1),
                    fontWeight: "bold",
                },
                "a:hover": {
                    color: getAlphaColor(ThemeColor.ACTION_WARNING as string, 0.6),
                },
            }}
            flexDirection={"column"}
        >
            <Text style={{ textAlign: "center" }}>
                HedgeFarm is sunsetting. All vaults are now in withdrawal-only mode. For more information, please see
                our <Link to={"https://twitter.com/hedge_farm/status/1668211580050509824"}>tweet</Link>.
            </Text>
        </Flex>
    );
};
