import {
    Center,
    Flex,
    Spinner,
    Stack,
    Tab,
    Table,
    TableContainer,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Tbody,
    Td,
    Tr,
} from "@chakra-ui/react";
import React from "react";
import { shallow } from "zustand/shallow";
import { usePromise } from "react-use-promise-matcher";
import { Strategy, TransactionError, useStrategyClient } from "src/contracts";
import { AppConfigContext } from "src/context";
import { ThemeColor } from "src/theme/palette";
import { Card, CardProps, Notice } from "src/uikit";
import { Heading } from "src/uikit/typography";
import { BigDecimal, BIG_ZERO } from "src/utils/bigDecimal";
import { formatDateTime, formatNumber } from "src/utils/format";
import { Maybe } from "true-myth";
import { Calendar } from "./components/Calendar";
import { NoticeProps, TransactionForm } from "./components/TransactionForm";
import { ContractTransaction } from "ethers";
import { useAccount, useNetwork } from "wagmi";
import { useStrategyStatsStore } from "src/stores/alphaVaultStatsStore";
import { AllowlistData, CalendarEvent, CalendarItem, HedgeFarmApiService } from "src/rest/hedgeFarm";
import { StatusCapsule, VaultStatus } from "./components/StatusCapsule";
import { AssetSymbol } from "src/theme";
import { noop } from "lodash";

interface Props<T extends Strategy> extends CardProps {
    strategy: T;
    currentEpoch: Maybe<CalendarItem>;
    currentEvent: Maybe<CalendarEvent>;
    nextOpenEvent: Maybe<CalendarEvent>;
    allowlistEnabled: boolean;
}

export const WalletAlpha1 = <T extends Strategy>({
    strategy,
    currentEpoch,
    currentEvent,
    nextOpenEvent,
    allowlistEnabled,
    ...props
}: Props<T>) => {
    const { strategies } = React.useContext(AppConfigContext);
    const hedgeFarmApiService = new HedgeFarmApiService(strategy);
    const [vaultStatus, setVaultStatus] = React.useState<VaultStatus>();
    const [isDepositDisabled, setIsDepositDisabled] = React.useState(false);
    const [isWithdrawalDisabled, setIsWithdrawalDisabled] = React.useState(false);
    const [accountAllowlistData, setAccountAllowlistData] = React.useState<AllowlistData | null>(null);

    const { chain } = useNetwork();
    const { address, isConnected } = useAccount();

    const client = useStrategyClient<T>(strategy);
    const stats = useStrategyStatsStore(
        state => ({
            isLoading: state.isLoading,
            cap: state.cap,
            depositToken: state.depositToken,
            iouToken: state.iouToken,
            isEpochRunning: state.isEpochRunning,
            tvl: state.totalValueLocked,
            allowance: state.allowance,
            balance: state.balance,
            depositTokenBalance: state.depositTokenBalance,
            refreshStats: state.refreshStats,
        }),
        shallow,
    );

    const maybeAccount = React.useMemo(() => Maybe.of(address), [address]);
    const isVaultFull = React.useMemo(() => stats.cap.gt(BIG_ZERO) && stats.tvl.gte(stats.cap), [stats.tvl, stats.cap]);
    const isPrelaunch = React.useMemo(
        () => currentEvent.mapOr(false, ({ id }) => id === "allowlist-prelaunch"),
        [currentEvent],
    );
    const isAllowlistOpen = React.useMemo(
        () => currentEvent.mapOr(false, ({ id }) => id === "allowlist-open"),
        [currentEvent],
    );
    const isPublicOpen = React.useMemo(
        () => currentEvent.mapOr(false, ({ id }) => id === "strategy-open"),
        [currentEvent],
    );
    const isV1 = true; //React.useMemo(() => strategy === Strategy.Alpha1TheCPPIooorV1, [strategy]);

    const fetchAccountAllowlistData = async (address: string) => {
        try {
            setAccountAllowlistData(await hedgeFarmApiService.getAllowlist(address));
        } catch (e) {
            console.error(`Failed to fetch account allowlist data: ${e}`);
        }
    };

    React.useEffect(() => {
        allowlistEnabled &&
            maybeAccount.match<void>({
                Just: fetchAccountAllowlistData,
                Nothing: () => setAccountAllowlistData(null),
            });
    }, [maybeAccount, allowlistEnabled]);

    React.useEffect(() => {
        Maybe.of(stats.isEpochRunning).match({
            Just: isEpochRunning => {
                const vaultStatus: VaultStatus = isPrelaunch
                    ? "launchingSoon"
                    : isEpochRunning
                    ? "locked"
                    : isAllowlistOpen
                    ? "allowlistOpen"
                    : "open";

                const isWalletDisconnected = !isConnected || !!chain?.unsupported;
                const isStrategyClosed =
                    !!isEpochRunning ||
                    (!isAllowlistOpen && !isPublicOpen) ||
                    (isAllowlistOpen && !accountAllowlistData?.isAllowed);
                const areTransactionsDisabled = isWalletDisconnected || (isStrategyClosed && !isV1);

                setVaultStatus(vaultStatus);
                setIsDepositDisabled(areTransactionsDisabled || isVaultFull || stats.depositTokenBalance.eq(0));
                setIsWithdrawalDisabled(areTransactionsDisabled || stats.balance.shares.eq(0));
            },
            Nothing: noop,
        });
    }, [isConnected, isVaultFull, isAllowlistOpen, isPublicOpen, isV1, chain, accountAllowlistData, stats]);

    const [approveResult, approveSpend] = usePromise<ContractTransaction, [string], TransactionError>(
        client.approveSpend,
    );

    const [depositResult, depositToStrategy] = usePromise<
        ContractTransaction,
        [string, BigDecimal, string],
        TransactionError
    >(client.deposit);

    const [withdrawResult, withdrawFromStrategy] = usePromise<
        ContractTransaction,
        [string, BigDecimal],
        TransactionError
    >(client.withdraw);

    const getDepositNotice = React.useCallback((): NoticeProps | null => {
        // TODO: refactor
        switch (vaultStatus) {
            case "locked":
                return {
                    text: `You can deposit or withdraw on day 1 of the next Epoch${nextOpenEvent.mapOr(
                        "",
                        ({ startDate }) => ` (${formatDateTime(startDate)})`,
                    )}.`,
                };
            case "allowlistOpen":
            // @ts-expect-error Fallthrough
            case "open":
                if (isVaultFull) {
                    return {
                        text: "The strategy cap has been reached. Deposits are no longer possible.",
                        color: ThemeColor.ACTION_WARNING,
                    };
                } else if (stats.balance.shares.gt(0) && vaultStatus === "open") {
                    // TODO: check if not first epoch
                    return {
                        text: "Your balance will be automatically re-subscribed to the next epoch if you do not withdraw it.",
                        color: ThemeColor.ACTION_PRIMARY,
                    };
                }
            default:
                return null;
        }
    }, [vaultStatus, isVaultFull, nextOpenEvent, stats.balance.shares]);

    const getWithdrawalNotice = React.useCallback((): NoticeProps | null => {
        // TODO: refactor
        switch (vaultStatus) {
            case "locked":
                return {
                    text: `You can deposit or withdraw on day 1 of the next Epoch${nextOpenEvent.mapOr(
                        "",
                        ({ startDate }) => ` (${formatDateTime(startDate)})`,
                    )}.`,
                };
            // @ts-expect-error Fallthrough
            case "open":
                if (stats.balance.shares.gt(0)) {
                    // TODO: check if not first epoch
                    return {
                        text: "Your balance will be automatically re-subscribed to the next epoch if you do not withdraw it.",
                        color: ThemeColor.ACTION_PRIMARY,
                    };
                }
            default:
                return null;
        }
    }, [vaultStatus, nextOpenEvent, stats.balance.shares]);

    return (
        <Card {...props}>
            {(!isV1 && currentEpoch.isNothing()) || stats.isLoading ? (
                <Center h={"11.2rem"}>
                    <Spinner />
                </Center>
            ) : (
                <Stack spacing={4}>
                    <Flex justifyContent={"space-between"}>
                        <Heading as={"h4"}>Wallet</Heading>
                        {vaultStatus && <StatusCapsule status={vaultStatus} />}
                    </Flex>
                    {!isV1 &&
                        currentEpoch.mapOr(<></>, epoch => (
                            <Calendar key={epoch.name} epoch={epoch} isOpen={vaultStatus === "open"} />
                        ))}
                    {maybeAccount.isJust() && (
                        <TableContainer>
                            <Table variant={"info"}>
                                <Tbody>
                                    <Tr>
                                        <Td>Deployed Amount</Td>
                                        <Td color={ThemeColor.CONTENT} isNumeric>
                                            {formatNumber(stats.balance.amount.toNumber(), {
                                                suffix: stats.depositToken.symbol,
                                                joinSeparator: " ",
                                                minimumFractionDigits: 2,
                                            })}
                                        </Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                        </TableContainer>
                    )}
                    <Tabs variant={"solid-rounded"} isFitted>
                        <TabList>
                            {/*{!isV1 && <Tab>Deposit</Tab>}*/}
                            <Tab>Withdrawal</Tab>
                        </TabList>
                        {isConnected && !isV1 && isPrelaunch && accountAllowlistData?.isAllowed && (
                            <Notice color={ThemeColor.ACTION_CONFIRMATION} icon={"generic.check"} mt={4}>
                                You are on our allowlist. Get ready to deposit soon.
                            </Notice>
                        )}
                        {isConnected &&
                            !isV1 &&
                            (isPrelaunch || isAllowlistOpen) &&
                            !accountAllowlistData?.isAllowed && (
                                <Notice color={ThemeColor.ACTION_WARNING} icon={"generic.warning"} mt={4}>
                                    You are not on our allowlist. Public deposits will start right after the allowlist
                                    window is closed.
                                </Notice>
                            )}
                        <TabPanels>
                            {/*{!isV1 && (*/}
                            {/*    <TabPanel>*/}
                            {/*        <TransactionForm*/}
                            {/*            transactionType={"deposit"}*/}
                            {/*            availableAmount={stats.depositTokenBalance}*/}
                            {/*            availableAmountSymbol={stats.depositToken.symbol as AssetSymbol}*/}
                            {/*            allowance={stats.allowance}*/}
                            {/*            onTransaction={(amount: BigDecimal) =>*/}
                            {/*                maybeAccount.match({*/}
                            {/*                    Just: account =>*/}
                            {/*                        stats.allowance.lt(amount)*/}
                            {/*                            ? approveSpend(account)*/}
                            {/*                            : depositToStrategy(*/}
                            {/*                                  account,*/}
                            {/*                                  amount,*/}
                            {/*                                  accountAllowlistData?.signature ?? "",*/}
                            {/*                              ),*/}
                            {/*                    Nothing: noop,*/}
                            {/*                })*/}
                            {/*            }*/}
                            {/*            approveResult={approveResult}*/}
                            {/*            transactionResult={depositResult}*/}
                            {/*            isConnected={isConnected}*/}
                            {/*            isDisabled={isDepositDisabled}*/}
                            {/*            isLoading={approveResult.isLoading || depositResult.isLoading}*/}
                            {/*            minAmount={strategies[strategy].minDepositAmount}*/}
                            {/*            maxAmount={strategies[strategy].maxDepositAmount}*/}
                            {/*            capRemainingAmount={stats.cap.minus(stats.tvl)}*/}
                            {/*            notice={getDepositNotice()}*/}
                            {/*        />*/}
                            {/*    </TabPanel>*/}
                            {/*)}*/}
                            <TabPanel>
                                <TransactionForm
                                    transactionType={"withdrawal"}
                                    availableAmount={stats.balance.shares}
                                    availableAmountSymbol={stats.iouToken.symbol as AssetSymbol}
                                    onTransaction={(amount: BigDecimal) =>
                                        maybeAccount.match({
                                            Just: account => withdrawFromStrategy(account, amount),
                                            Nothing: noop,
                                        })
                                    }
                                    transactionResult={withdrawResult}
                                    isConnected={isConnected}
                                    isDisabled={isWithdrawalDisabled}
                                    isLoading={withdrawResult.isLoading}
                                />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Stack>
            )}
        </Card>
    );
};
