import React from "react";
import { ExecutionData, HedgeFarmApiService } from "src/rest/hedgeFarm";
import { Strategy } from "../types";

export const useExecutionData = (strategy: Strategy) => {
    const hedgeFarmApiService = new HedgeFarmApiService(strategy);
    const [executionData, setExecutionData] = React.useState<ExecutionData>();

    const fetchExecutionData = async () => {
        try {
            setExecutionData(await hedgeFarmApiService.getExecution());
        } catch (e) {
            console.error(`Failed to fetch execution data: ${e}`);
        }
    };

    React.useEffect(() => {
        fetchExecutionData();
    }, [strategy]);

    return { executionData };
};
