import React from "react";
import { CalendarEvent, CalendarItem, HedgeFarmApiService } from "src/rest/hedgeFarm";
import { Maybe } from "true-myth";
import { Strategy } from "../types";

export const useCalendarData = (strategy: Strategy) => {
    const hedgeFarmApiService = new HedgeFarmApiService(strategy);
    const [currentEpoch, setCurrentEpoch] = React.useState<Maybe<CalendarItem>>(Maybe.nothing());
    const [currentEvent, setCurrentEvent] = React.useState<Maybe<CalendarEvent>>(Maybe.nothing());
    const [nextOpenEvent, setNextOpenEvent] = React.useState<Maybe<CalendarEvent>>(Maybe.nothing());

    const fetchCalendarData = async () => {
        try {
            const now = new Date();
            const calendar = await hedgeFarmApiService.getCalendar();
            const currentEpoch = calendar.find(({ endDate }) => endDate >= now);
            const nextEpoch = calendar.find(({ startDate }) => startDate > (currentEpoch?.startDate ?? now));

            setCurrentEpoch(Maybe.of(currentEpoch));
            setCurrentEvent(
                Maybe.of(currentEpoch?.events.find(({ startDate, endDate }) => startDate < now && endDate > now)),
            );
            setNextOpenEvent(Maybe.of(nextEpoch?.events.find(({ id }) => id === "strategy-open")));
        } catch (e) {
            console.error(`Failed to fetch calendar data: ${e}`);
        }
    };

    React.useEffect(() => {
        fetchCalendarData();
    }, [strategy]);

    return { currentEpoch, currentEvent, nextOpenEvent };
};
