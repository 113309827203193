import { Strategy, StrategyAbi } from "../index";
import React from "react";
import { AppConfigContext } from "../../context";
import { always } from "ramda";
import Alpha1TheCPPIooorV1Abi from "../abi/alpha1TheCPPIooorV1Abi.json";
import Alpha1TheCPPIooorV2Abi from "../abi/alpha1TheCPPIooorV2Abi.json";
import Alpha2SmartFarmooorAbi from "../abi/alpha2SmartFarmooorAbi.json";
import { providers, Signer } from "ethers";
import { useContract } from "wagmi";
import { Abi } from "abitype";
import { Maybe } from "true-myth";

export const abiMap: Record<Strategy, Abi> = {
    [Strategy.Alpha1TheCPPIooorV1]: Alpha1TheCPPIooorV1Abi as Abi,
    [Strategy.Alpha1TheCPPIooorV2]: Alpha1TheCPPIooorV2Abi as Abi,
    [Strategy.Alpha2SmartFarmooor]: Alpha2SmartFarmooorAbi as Abi,
};

export const useStrategyContract = <T extends Strategy>(
    strategy: T,
    signerOrProvider?: Signer | providers.Provider,
): StrategyAbi<T> => {
    const { strategies } = React.useContext(AppConfigContext);
    const address: string = React.useMemo(always(strategies[strategy].address), [strategies, strategy]);
    const abi = React.useMemo(always(abiMap[strategy]), [strategy]);

    return Maybe.of(useContract({ address, abi, signerOrProvider })).unwrapOrElse(() => {
        throw new Error(`Failed to create contract for strategy: ${strategies[strategy].name}`);
    }) as StrategyAbi<T>;
};
