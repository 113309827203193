import { Flex, FlexProps, Table, TableContainer, Tbody } from "@chakra-ui/react";
import styled from "@emotion/styled";
import React from "react";
import { Stat, StatProps, StatTr } from "./Stat";

const StatWrapper = styled(Flex)`
    justify-content: space-between;

    & > div {
        flex: 1;

        &:not(:first-of-type) {
            border-left-width: 1px;
        }
    }
`;

interface Props extends FlexProps {
    stats: StatProps[];
}

export const Stats = ({ stats, ...props }: Props) => {
    return (
        <>
            <StatWrapper display={{ base: "none", md: "flex" }} {...props}>
                {stats.map((statProps, index) => (
                    <Stat key={index} {...statProps} />
                ))}
            </StatWrapper>
            <TableContainer display={{ base: "block", md: "none" }} {...props}>
                <Table variant={"info"}>
                    <Tbody>
                        {stats.map((statProps, index) => (
                            <StatTr key={index} {...statProps} />
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
        </>
    );
};
