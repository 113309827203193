import React from "react";
import { createRoot } from "react-dom/client";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import { appConfig } from "./config";
import { IntlProvider } from "react-intl";
import locale_en from "./translations/en.json";
import locale_fr from "./translations/fr.json";
import { Maybe } from "true-myth";
import { AppConfigContext } from "./context";
import { theme } from "./theme";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import "@fontsource/outfit";
import { WagmiConfig } from "wagmi";
import { client } from "./wagmi";
import "@rainbow-me/rainbowkit/styles.css";
import { BrowserRouter } from "react-router-dom";

const intlData = {
    en: locale_en,
    fr: locale_fr,
};

const lang = navigator.language.split(/[-_]/)[0];

const getMessages = (language: string) => Maybe.of(intlData[language as keyof typeof intlData]).unwrapOr(intlData.en);

const root = createRoot(
    Maybe.of(document.getElementById("root")).unwrapOrElse(() => {
        throw new Error("Root container not found");
    }),
);

root.render(
    <React.StrictMode>
        <AppConfigContext.Provider value={appConfig}>
            <BrowserRouter>
                <ChakraProvider theme={theme}>
                    <IntlProvider locale={lang} messages={getMessages(lang)}>
                        <WagmiConfig client={client}>
                            <ColorModeScript initialColorMode={theme.config.initialColorMode} />
                            <App />
                        </WagmiConfig>
                    </IntlProvider>
                </ChakraProvider>
            </BrowserRouter>
        </AppConfigContext.Provider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
