import { Box, Flex, HStack, Table, TableContainer, Tbody, Td, Tooltip, Tr } from "@chakra-ui/react";
import React from "react";
import { ThemeColor } from "src/theme/palette";
import { Card, CardProps, TooltipLabel } from "src/uikit";
import { Icon } from "src/uikit/icon";
import { Heading } from "src/uikit/typography";

interface OverviewTableRow {
    title: string;
    content: React.ReactNode;
    tooltip: string;
}

interface Props extends CardProps {
    body: React.ReactNode;
    tableContent: OverviewTableRow[];
}

export const Overview = ({ body, tableContent, ...props }: Props) => {
    const renderDetail = (content: React.ReactNode, tooltipLabel: string) => (
        <HStack justifyContent={"flex-end"} spacing={2}>
            {content}
            <Tooltip label={<TooltipLabel>{tooltipLabel}</TooltipLabel>}>
                <Flex>
                    <Icon name={"generic.info"} fill={"gray.500"} w={"2rem"} h={"2rem"} />
                </Flex>
            </Tooltip>
        </HStack>
    );

    return (
        <Card {...props}>
            <Flex direction={"column"}>
                <Heading as={"h4"} color={ThemeColor.ACTION_PRIMARY}>
                    Overview
                </Heading>
                <Box mt={4}>{body}</Box>
                <TableContainer mt={6}>
                    <Table variant={"info"}>
                        <Tbody>
                            {tableContent.map(({ title, content, tooltip }, index) => (
                                <Tr key={`${title}${index}`}>
                                    <Td>{title}</Td>
                                    <Td>{renderDetail(content, tooltip)}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Flex>
        </Card>
    );
};
