import { Flex, HStack, Table, TableContainer, Tbody, Td, Text, Tooltip, Tr } from "@chakra-ui/react";
import React from "react";
import { AppConfigContext } from "src/context";
import { StrategyProperties } from "src/strategies.config";
import { ThemeColor } from "src/theme/palette";
import { Card, CardProps, TooltipLabel } from "src/uikit";
import { Icon } from "src/uikit/icon";
import { formatAddress } from "src/utils/format";
import { Maybe } from "true-myth";
import { Link } from "../Link";
import { Strategy } from "../../contracts";
import { ReactComponent as LogoHacken } from "./assets/logo-hacken.svg";
import { getColorValue } from "../../theme/utils";

interface Props<T extends Strategy> extends CardProps {
    strategy: T;
}

export const StrategyExtras = <T extends Strategy>({ strategy, ...props }: Props<T>) => {
    const { strategies } = React.useContext(AppConfigContext);

    const getContractHref = ({ address, chain }: StrategyProperties): string | null =>
        Maybe.of(chain.explorerUrl).mapOr(null, url => `${url}address/${address}`);

    const renderExtra = (text: string, to: string | null, target: React.HTMLAttributeAnchorTarget = "_blank") => (
        <HStack justifyContent={"flex-end"}>
            <Text>{text}</Text>
            {to && (
                <Link to={to} target={target}>
                    <Icon name={"generic.link"} fill={ThemeColor.ACTION_PRIMARY} w={"2rem"} h={"2rem"} />
                </Link>
            )}
        </HStack>
    );

    const renderSecurity = () => {
        if (strategy == Strategy.Alpha2SmartFarmooor) {
            return (
                <HStack justifyContent={"flex-end"} spacing={2}>
                    <Text>Audited by</Text>
                    <Link to={"https://hacken.io"} target={"_blank"}>
                        <LogoHacken height={"2rem"} width={"9rem"} fill={getColorValue(ThemeColor.CONTENT)} />
                    </Link>
                    <Link to={`/documents/${strategy.toLowerCase()}/audit.pdf`} target={"_blank"}>
                        <Icon name={"generic.link"} fill={ThemeColor.ACTION_PRIMARY} w={"2rem"} h={"2rem"} />
                    </Link>
                </HStack>
            );
        } else {
            return (
                <HStack justifyContent={"flex-end"}>
                    <Text>Internally Audited</Text>
                    <Tooltip
                        label={
                            <TooltipLabel>
                                This vault is in a Beta stage and therefore is running only internally audited smart
                                contracts. Use at your own risk.
                            </TooltipLabel>
                        }
                    >
                        <Flex>
                            <Icon name={"generic.info"} fill={ThemeColor.ACTION_PRIMARY} w={"2rem"} h={"2rem"} />
                        </Flex>
                    </Tooltip>
                </HStack>
            );
        }
    };

    return (
        <Card py={"1.6rem"} {...props}>
            <TableContainer>
                <Table variant={"info"}>
                    <Tbody>
                        <Tr>
                            <Td>Performance Fee</Td>
                            <Td textAlign={"right"} py={"0.85rem"}>
                                20%
                            </Td>
                        </Tr>
                        <Tr>
                            <Td>Contract</Td>
                            <Td>
                                {renderExtra(
                                    formatAddress(strategies[strategy].address.toUpperCase()),
                                    getContractHref(strategies[strategy]),
                                )}
                            </Td>
                        </Tr>
                        <Tr>
                            <Td>Security</Td>
                            <Td>{renderSecurity()}</Td>
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
        </Card>
    );
};
