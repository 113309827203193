import React from "react";
import { Container } from "@chakra-ui/react";
import { Link } from "../Link";
import { ReactComponent as LogoHedgeFarmLong } from "./assets/Logo.svg";
import { ReactComponent as LogoHedgeFarmSmall } from "./assets/LogoSmall.svg";
import { getColorValue } from "../../theme/utils";
import { ThemeColor } from "../../theme/palette";

export const Logo = () => {
    return (
        <Link to="/">
            <Container display={{ base: "none", md: "block" }}>
                <LogoHedgeFarmLong height={"10rem"} width={"15rem"} fill={getColorValue(ThemeColor.CONTENT)} />
            </Container>
            <Container display={{ base: "block", md: "none" }}>
                <LogoHedgeFarmSmall height={"2.5rem"} width={"2.5rem"} fill={getColorValue(ThemeColor.CONTENT)} />
            </Container>
        </Link>
    );
};
