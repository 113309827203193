import { Chain, Wallet } from "@rainbow-me/rainbowkit";
import { ConnectorNotFoundError, normalizeChainId } from "@wagmi/core";
import { InjectedConnector } from "wagmi/connectors/injected";

class CoreWalletConnector extends InjectedConnector {
    async getChainId() {
        const provider = (await this.getProvider()) as unknown as { chainId?: string };
        if (!provider || !provider.chainId) throw new ConnectorNotFoundError();
        return normalizeChainId(provider.chainId);
    }
}

interface CoreWalletOptions {
    chains: Chain[];
    shimDisconnect?: boolean;
}

export const coreWallet = ({ chains, shimDisconnect }: CoreWalletOptions): Wallet => {
    const isCoreWalletInjected =
        typeof window !== "undefined" && window.ethereum?.isAvalanche === true && window.ethereum?.isMetaMask === true;

    return {
        id: "coreWallet",
        name: "Core Wallet",
        iconUrl: async () => (await import(`./coreWallet.svg`)).default,
        iconBackground: "#000",
        installed: isCoreWalletInjected,
        downloadUrls: {
            browserExtension: "https://core.app/",
        },
        createConnector: () => ({
            connector: new CoreWalletConnector({
                chains,
                options: { shimDisconnect },
            }),
        }),
    };
};
