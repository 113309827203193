import {
    Table,
    TableProps,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Text,
    HStack,
    Center,
    Flex,
    Tooltip,
    Image,
} from "@chakra-ui/react";
import React from "react";
import { AllocationItem } from "src/rest/hedgeFarm";
import { ThemeColor } from "../../../theme/palette";
import { Link } from "../../Link";
import { ReactComponent as LogoStargate } from "../assets/logo-icon-stargate.svg";
import { ReactComponent as LogoBenqi } from "../assets/logo-icon-benqi.svg";
import { ReactComponent as LogoAave } from "../assets/logo-icon-aave.svg";
import LogoTraderJoePNG from "../assets/logo-icon-traderjoe.png";
import { getColorValue } from "../../../theme/utils";
import { formatNumber } from "../../../utils/format";
import { Icon } from "../../../uikit/icon";
import { Gauge, GaugeValue } from "./Gauge";
import { Shield, ShieldValue } from "./Shield";

interface Props extends TableProps {
    data: AllocationItem[];
}

type AllocationProtocol = "Stargate" | "BenQi" | "Trader Joe" | "AAVE";

export const AllocationTable = ({ data, ...props }: Props) => {
    const renderProtocol = (protocol: AllocationProtocol): JSX.Element => {
        switch (protocol) {
            case "Stargate":
                return (
                    <Link to={"https://stargate.finance"} target={"_blank"}>
                        <HStack display="flex" spacing={2}>
                            <LogoStargate height={"2rem"} width={"2rem"} />
                            <Text>{protocol}</Text>
                        </HStack>
                    </Link>
                );
            case "BenQi":
                return (
                    <Link to={"https://benqi.fi"} target={"_blank"}>
                        <HStack display="flex" spacing={2}>
                            <LogoBenqi height={"2rem"} width={"2rem"} />
                            <Text>{protocol}</Text>
                        </HStack>
                    </Link>
                );
            case "Trader Joe":
                return (
                    <Link to={"https://traderjoexyz.com"} target={"_blank"}>
                        <HStack display="flex" spacing={2}>
                            <Image src={LogoTraderJoePNG} height={"2rem"} width={"2rem"} alt={"TraderJoePNG"} />
                            <Text>{protocol}</Text>
                        </HStack>
                    </Link>
                );
            case "AAVE":
                return (
                    <Link to={"https://aave.com"} target={"_blank"}>
                        <HStack display="flex" spacing={2}>
                            <LogoAave height={"2rem"} width={"2rem"} fill={getColorValue(ThemeColor.CONTENT)} />
                            <Text>{protocol}</Text>
                        </HStack>
                    </Link>
                );
        }
    };

    return (
        <Table {...props}>
            <Thead>
                <Tr>
                    <Th>Market</Th>
                    <Th textAlign={"center"}>
                        <Center>Allocation</Center>
                    </Th>
                    <Th textAlign={"center"} display={{ base: "none", md: "table-cell" }}>
                        <Center>
                            <HStack spacing={1} justifyContent={"flex-end"}>
                                <Text>Performance</Text>
                                <Tooltip label={"Measured according to the APY of the market, on a scale from 1 to 10"}>
                                    <Flex>
                                        <Icon name={"generic.info"} fill={"gray.500"} w={"1.3rem"} h={"1.3rem"} />
                                    </Flex>
                                </Tooltip>
                            </HStack>
                        </Center>
                    </Th>
                    <Th textAlign={"center"} display={{ base: "none", md: "table-cell" }}>
                        <Center>
                            <HStack spacing={1} justifyContent={"flex-end"}>
                                <Text>Strength</Text>
                                <Tooltip label={"Measured according to the TVL of the market, on a scale from 1 to 10"}>
                                    <Flex>
                                        <Icon name={"generic.info"} fill={"gray.500"} w={"1.3rem"} h={"1.3rem"} />
                                    </Flex>
                                </Tooltip>
                            </HStack>
                        </Center>
                    </Th>
                    <Th textAlign={"center"} display={{ base: "none", xl: "table-cell" }}>
                        <Center>
                            <HStack spacing={1} justifyContent={"flex-end"}>
                                <Text>Safety</Text>
                                <Tooltip
                                    label={"Measured according to the risks of the market, on a scale from 0 to 3"}
                                >
                                    <Flex>
                                        <Icon name={"generic.info"} fill={"gray.500"} w={"1.3rem"} h={"1.3rem"} />
                                    </Flex>
                                </Tooltip>
                            </HStack>
                        </Center>
                    </Th>
                </Tr>
            </Thead>
            <Tbody>
                {data.map(({ market, allocation, performance, strength, safety }, index) => (
                    <Tr key={`${market}${index}`}>
                        <Td>{renderProtocol(market as AllocationProtocol)}</Td>
                        <Td textAlign={"center"} fontWeight={"bold"} color={ThemeColor.CONTENT}>
                            {formatNumber(allocation, { suffix: "%" })}
                        </Td>
                        <Td display={{ base: "none", md: "table-cell" }}>
                            <Center>
                                <Gauge
                                    value={performance as GaugeValue}
                                    width={"5rem"}
                                    alt={`performance-${performance}`}
                                />
                            </Center>
                        </Td>
                        <Td textAlign={"center"} display={{ base: "none", md: "table-cell" }}>
                            <Center>
                                <Gauge value={strength as GaugeValue} width={"5rem"} alt={`strength-${strength}`} />
                            </Center>
                        </Td>
                        <Td textAlign={"center"} display={{ base: "none", xl: "table-cell" }}>
                            <Center>
                                <Shield value={safety as ShieldValue} alt={`shield-${safety}`} />
                            </Center>
                        </Td>
                    </Tr>
                ))}
            </Tbody>
        </Table>
    );
};
