import { Flex, HeadingProps, HStack, Stack, StackProps, Td, Text, Tooltip, Tr } from "@chakra-ui/react";
import React from "react";
import { ThemeColor } from "src/theme/palette";
import { getColorValue } from "src/theme/utils";
import { hexToRGBA } from "src/utils/color";
import { Maybe } from "true-myth";
import { Icon, IconName } from "./icon";
import { IconBox } from "./IconBox";
import { Heading } from "./typography";

export interface StatProps extends StackProps {
    label: string;
    value: string;
    headingSize?: HeadingProps["fontSize"];
    tooltipLabel?: React.ReactNode;
    icon?: IconName;
}

const renderIcon = (icon: IconName) => (
    <IconBox
        icon={icon}
        iconColor={ThemeColor.ACTION_PRIMARY}
        iconSize={{ base: "2rem", md: "2.4rem", lg: "2rem", xl: "2.4rem" }}
        boxColor={hexToRGBA(Maybe.of(getColorValue(ThemeColor.ACTION_PRIMARY)).unwrapOr(""), 0.1)}
        boxSize={{ base: "3.2rem", md: "4.8rem", lg: "3.2rem", xl: "4.8rem" }}
    />
);

const renderTooltip = (tooltipLabel: React.ReactNode) => (
    <Tooltip label={tooltipLabel}>
        <Flex>
            <Icon name={"generic.info"} fill={"gray.500"} w={"1.6rem"} h={"1.6rem"} />
        </Flex>
    </Tooltip>
);

export const Stat = ({ label, value, headingSize, tooltipLabel, icon, ...props }: StatProps) => {
    const size = headingSize ?? { base: "2rem", md: "2rem", lg: "2rem", xl: "2rem" };
    return (
        <HStack spacing={4} {...props}>
            {icon && renderIcon(icon)}
            <Stack flex={1} spacing={0} flexDirection={{ base: "row", sm: "column" }} justifyContent={"space-between"}>
                <HStack>
                    <Text color={"gray.500"}>{label}</Text>
                    {tooltipLabel && renderTooltip(tooltipLabel)}
                </HStack>
                <Heading as={"h4"} fontSize={size} lineHeight={size}>
                    {value}
                </Heading>
            </Stack>
        </HStack>
    );
};

export const StatTr = ({ label, value, tooltipLabel, icon }: StatProps) => (
    <Tr>
        <Td py={2}>
            <HStack spacing={4}>
                {icon && renderIcon(icon)}
                <Text>{label}</Text>
            </HStack>
        </Td>
        <Td py={2}>
            <HStack spacing={2} justifyContent={"flex-end"}>
                <Text>{value}</Text>
                {tooltipLabel && renderTooltip(tooltipLabel)}
            </HStack>
        </Td>
    </Tr>
);
