import { Maybe } from "true-myth";
import {
    AllocationData,
    AllocationDataDto,
    AllocationItem,
    AllocationItemDto,
    CalendarData,
    CalendarDataDto,
    CalendarEvent,
    CalendarEventDto,
    CalendarEventId,
    CalendarItem,
    CalendarItemDto,
    EpochPerformanceItem,
    EpochPerformanceItemDto,
    ExecutionData,
    ExecutionDataDto,
    ExecutionItem,
    ExecutionItemDto,
    ExecutionPositionId,
    ExecutionProtocolId,
    PerformanceData,
    PerformanceDataDto,
    PerformanceItem,
    PerformanceItemDto,
} from "./types";

const mapCalendarEventDto = (dto: CalendarEventDto): CalendarEvent => ({
    id: dto.id as CalendarEventId,
    startDate: new Date(dto.startDate),
    endDate: new Date(dto.endDate),
});

const mapCalendarItemDto = (dto: CalendarItemDto): CalendarItem => ({
    name: dto.name,
    startDate: new Date(dto.startDate),
    endDate: new Date(dto.endDate),
    events: dto.events.map(mapCalendarEventDto).sort((a, b) => a.startDate.getTime() - b.startDate.getTime()),
});

export const mapCalendarDataDto = (dto: CalendarDataDto): CalendarData =>
    dto.map(mapCalendarItemDto).sort((a, b) => a.startDate.getTime() - b.startDate.getTime());

const mapPerformanceItemDto = (
    dto: PerformanceItemDto,
    key: keyof PerformanceItem,
    amount: number,
): PerformanceItem => ({
    date: new Date(dto.date),
    [key]: amount * dto.performance,
    benchmark: Maybe.of(dto.benchmark)
        .map(benchmark => benchmark * amount)
        .unwrapOr(undefined),
});

const mapEpochPerformanceItemDto = (dto: EpochPerformanceItemDto): EpochPerformanceItem => ({
    name: dto.name,
    startDate: new Date(dto.startDate),
    endDate: new Date(dto.endDate),
    annualizedPerformance: dto.annualizedPerformance * 100,
    epochPerformance: dto.epochPerformance * 100,
});

const mergePerformanceArrays = (backtest: PerformanceItem[], live: PerformanceItem[]): PerformanceItem[] => [
    ...backtest.slice(0, -1),
    { ...backtest.pop(), ...live.shift() } as PerformanceItem,
    ...live,
];

export const mapPerformanceDataDto = (dto: PerformanceDataDto): PerformanceData => ({
    averageApy: dto.averageApy * 100,
    historicalPerformance: {
        investmentAmount: dto.historicalPerformance.investmentAmount,
        yield: dto.historicalPerformance.yield,
        apy: dto.historicalPerformance.apy * 100,
        performance: mergePerformanceArrays(
            dto.historicalPerformance.backtestPerformance.map(item =>
                mapPerformanceItemDto(item, "backtest", dto.historicalPerformance.investmentAmount),
            ),
            dto.historicalPerformance.livePerformance.map(item =>
                mapPerformanceItemDto(item, "live", dto.historicalPerformance.investmentAmount),
            ),
        ),
    },
    epochPerformance: dto.epochPerformance?.map(mapEpochPerformanceItemDto),
    last24hApy: dto.last24hApy * 100,
});

export const mapExecutionItemDto = (dto: ExecutionItemDto): ExecutionItem => ({
    protocol: dto.protocol as ExecutionProtocolId,
    position: dto.position as ExecutionPositionId,
    asset: dto.asset,
    assetCode: dto.assetCode,
    size: dto.size,
    entryPrice: dto.entryPrice,
});

export const mapExecutionDataDto = (dto: ExecutionDataDto): ExecutionData => ({
    positions: dto.positions.map(item => mapExecutionItemDto(item)),
});

export const mapAllocationItemDto = (dto: AllocationItemDto): AllocationItem => ({
    market: dto.market,
    allocation: dto.allocation * 100,
    performance: dto.performance,
    strength: dto.strength,
    safety: dto.safety,
});

export const mapAllocationDataDto = (dto: AllocationDataDto): AllocationData => ({
    allocations: dto.allocations.map(item => mapAllocationItemDto(item)),
});
