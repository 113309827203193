import { strategiesConfig, StrategiesConfig } from "./strategies.config";

export interface AppConfig {
    strategies: StrategiesConfig;
    aaveApiBaseUrl: string;
    aavePoolId: string;
    lidoApiBaseUrl: string;
    coingeckoApiBaseUrl: string;
    hedgeFarmApiBaseUrl: string;
    termsOfUseUrl: string;
}

export const appConfig: AppConfig = {
    strategies: strategiesConfig,
    aaveApiBaseUrl: "https://aave-api-v2.aave.com/data",
    aavePoolId: "0xb53c1a33016b2dc2ff3653530bff1848a515c8c5",
    lidoApiBaseUrl: "https://stake.lido.fi/api",
    coingeckoApiBaseUrl: "https://api.coingecko.com/api/v3",
    hedgeFarmApiBaseUrl: "https://api.hedgefarm.finance",
    termsOfUseUrl: "/terms-of-use",
};
