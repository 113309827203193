import React from "react";
import {
    Overview,
    Performance,
    StrategyHeader,
    TermsModal,
    WalletAlpha2,
    StrategyExtras,
    ChartAlpha2,
} from "src/components";
import { Strategy, useAllocationData, useCalendarData, usePerformanceData, useStrategyClient } from "src/contracts";
import { assets, AssetSymbol } from "src/theme";
import { Column, Template } from "src/uikit/template";
import { Maybe } from "true-myth";
import { useAccount } from "wagmi";
import { Box, Text } from "@chakra-ui/react";
import { TooltipLabel } from "../uikit";
import { Allocation } from "../components/Allocation";
import { useStrategyStatsStore } from "../stores/alphaVaultStatsStore";

const STATS_REFRESH_INTERVAL = 10000;

interface Props<T extends Strategy> {
    strategy: T;
    strategySymbol: AssetSymbol;
    notice?: JSX.Element;
}

export const Alpha2Page = <T extends Strategy>({ strategy, strategySymbol, notice }: Props<T>) => {
    const client = useStrategyClient(strategy);
    const { address } = useAccount();
    const resetStats = useStrategyStatsStore(state => state.resetStats);
    const refreshStats = useStrategyStatsStore(state => state.refreshStats);
    const refreshStrategyStats = () => refreshStats(client, Maybe.of(address));
    const { currentEvent } = useCalendarData(strategy);
    const { performanceData } = usePerformanceData(strategy);
    const { allocationData } = useAllocationData(strategy);

    React.useEffect(() => {
        resetStats();
        refreshStrategyStats();
        const interval = setInterval(refreshStrategyStats, STATS_REFRESH_INTERVAL);
        return () => {
            clearInterval(interval);
        };
    }, [client, address]);

    return (
        <>
            <TermsModal />
            {notice && <Box mb={{ base: "1.6rem", sm: "2rem", md: "2.6rem" }}>{notice}</Box>}
            <Template rowGap={{ base: "1.6rem", sm: "2rem", md: "2.6rem", xl: "3.2rem" }}>
                <Column width={{ base: "100%", lg: "58.4325%", xl: "57.4325%" }}>
                    <StrategyHeader
                        order={1}
                        name={"The Smart Farmooor"}
                        subtitle={"Alpha #2"}
                        symbol={strategySymbol}
                        averageApy={Maybe.of(performanceData?.last24hApy)}
                        currentEvent={currentEvent}
                        showCounter={currentEvent.mapOr(true, event => event.id !== "strategy-open")}
                        statistics={[
                            {
                                label: "Risk",
                                value: "Low",
                                tooltipLabel: (
                                    <TooltipLabel heading={"Vault Risk"}>
                                        The overall risk of the vault. For more information please refer to the risk
                                        sheet.
                                    </TooltipLabel>
                                ),
                                icon: "generic.info",
                            },
                            {
                                label: "Lock-up",
                                value: "No",
                                tooltipLabel: (
                                    <TooltipLabel heading={"Lock-up Period"}>
                                        Time during which your funds are locked in order to execute the strategy.
                                    </TooltipLabel>
                                ),
                                icon: "generic.locked",
                            },
                            {
                                label: "Protection",
                                value: "100%",
                                tooltipLabel: (
                                    <TooltipLabel heading={"Capital Protected"}>
                                        Percentage of capital protection.
                                    </TooltipLabel>
                                ),
                                icon: "generic.protection",
                            },
                        ]}
                        apyLabel={"Current APY (24h)"}
                        apyExplanation={"APY derived from the yield generated in the last 24h."}
                    />
                    <Allocation data={Maybe.of(allocationData)} order={2} />
                    <Overview
                        body={
                            <>
                                <Text>
                                    Alpha #2 - The Smart Farmooor is a vault that helps you earn a higher return on your
                                    cryptocurrency by analyzing and allocating your assets across multiple yield
                                    markets.
                                </Text>
                                <Text mt={2}>
                                    It uses complex calculations to ensure your assets are allocated in the most optimal
                                    way, taking into account factors like performance, strength, and safety. It’s a
                                    great choice for those looking to maximize their returns while minimizing their
                                    risk.
                                </Text>
                            </>
                        }
                        tableContent={[
                            {
                                title: "Source of yield",
                                content: <Text>Yield Farming</Text>,
                                tooltip: "The different yield-generation processes we use in the strategy.",
                            },
                            {
                                title: "Deposit/Withdrawal Period",
                                content: <Text>Instant</Text>,
                                tooltip: "Number of days available to withdraw/deposit funds into the vault.",
                            },
                            {
                                title: "Dynamic re-allocation",
                                content: <Text>Daily</Text>,
                                tooltip: "Frequency of portfolio allocation rebalance across yield markets",
                            },
                        ]}
                        order={3}
                    />
                    <Performance
                        bodyText={
                            "This chart represents the vault performance against our benchmark (equally weighted allocation) since the start of the strategy with a 1 BTC.b initial investment. Values are denominated in BTC.b."
                        }
                        data={Maybe.of(performanceData)}
                        Chart={ChartAlpha2}
                        investmentValueFormatterOptions={{ suffix: ` ${assets[strategySymbol].displaySymbol}` }}
                        returnsValueFormatterOptions={{
                            maximumFractionDigits: assets[strategySymbol].maximumFractionDigits,
                        }}
                        order={4}
                    />
                </Column>
                <Column width={{ base: "100%", lg: "38.1622%", xl: "37.1622%" }}>
                    <WalletAlpha2<T>
                        strategy={strategy}
                        symbol={strategySymbol}
                        currentEvent={currentEvent}
                        order={2}
                    />
                    <StrategyExtras<T> strategy={strategy} order={3} />
                </Column>
            </Template>
        </>
    );
};
