import { ComponentStyleConfig } from "@chakra-ui/react";
import { ThemeColor } from "../palette";

export const Progress: ComponentStyleConfig = {
    baseStyle: {
        track: {
            borderLeftRadius: "full",
            borderRightRadius: "full",
            bgColor: ThemeColor.GENERAL,
        },
        filledTrack: {
            backgroundColor: ThemeColor.ACTION_PRIMARY,
        },
    },
};
