import { Box, Flex, HStack, Stack, StackProps, Text } from "@chakra-ui/react";
import styled from "@emotion/styled";
import React from "react";
import { CalendarItem } from "src/rest/hedgeFarm";
import { ThemeColor } from "src/theme/palette";
import { IconBox } from "src/uikit";
import { formatDate } from "src/utils/format";

const Dot = styled(Box)`
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 0.8rem;
`;

interface Props extends StackProps {
    epoch: CalendarItem;
    isOpen: boolean;
}

export const Calendar = ({ epoch, isOpen, ...props }: Props) => {
    const [passedDays, setPassedDays] = React.useState(0);
    const [totalDays, setTotalDays] = React.useState(27);

    const getDayCount = (start: Date, end: Date): number =>
        Math.ceil((end.getTime() - start.getTime()) / (1000 * 3600 * 24));

    React.useEffect(() => {
        const strategyOpenEvent = epoch.events.find(event => event.id === "strategy-locked");
        if (strategyOpenEvent) {
            setPassedDays(getDayCount(strategyOpenEvent.startDate, new Date()));
            setTotalDays(getDayCount(strategyOpenEvent.startDate, strategyOpenEvent.endDate));
        }
    }, [epoch]);

    return (
        <Stack spacing={4} p={"1.2rem"} bg={ThemeColor.GENERAL} borderRadius={"0.8rem"} {...props}>
            <Flex justifyContent={"space-between"} alignItems={"center"}>
                <Text fontWeight={"semibold"}>{epoch.name}</Text>
                <HStack spacing={2}>
                    <Text>
                        {formatDate(epoch.startDate)} - {formatDate(epoch.endDate)}
                    </Text>
                    <IconBox icon={"generic.calendar"} boxColor={ThemeColor.CARDS} />
                </HStack>
            </Flex>
            <HStack spacing={3} display={{ base: "none", sm: "flex" }}>
                <Stack spacing={2} alignItems={"center"}>
                    <Dot bg={isOpen ? ThemeColor.ACTION_CONFIRMATION : ThemeColor.MID_RANGE} />
                    <Text color={isOpen ? ThemeColor.ACTION_CONFIRMATION : "gray.400"}>Open</Text>
                </Stack>
                <Stack spacing={2} w={"100%"}>
                    <Flex justifyContent={"space-between"}>
                        {[...Array(totalDays)].map((_, i) => (
                            <Dot key={i} bg={i < passedDays ? ThemeColor.ACTION_WARNING : ThemeColor.MID_RANGE} />
                        ))}
                    </Flex>
                    <Text color={"gray.400"}>Lock-up ({totalDays} Days)</Text>
                </Stack>
            </HStack>
        </Stack>
    );
};
