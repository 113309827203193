import React from "react";
import { AllocationData, HedgeFarmApiService } from "src/rest/hedgeFarm";
import { Strategy } from "../types";

export const useAllocationData = (strategy: Strategy) => {
    const hedgeFarmApiService = new HedgeFarmApiService(strategy);
    const [allocationData, setAllocationData] = React.useState<AllocationData>();

    const fetchAllocationData = async () => {
        try {
            setAllocationData(await hedgeFarmApiService.getAllocation());
        } catch (e) {
            console.error(`Failed to fetch allocation data: ${e}`);
        }
    };

    React.useEffect(() => {
        fetchAllocationData();
    }, [strategy]);

    return { allocationData };
};
