import { Box, Flex, HStack, Text } from "@chakra-ui/react";
import styled from "@emotion/styled";
import React from "react";
import Countdown, { CountdownRenderProps } from "react-countdown";
import { CalendarEvent, CalendarEventId } from "src/rest/hedgeFarm";
import { ThemeColor } from "src/theme/palette";
import { getAlphaColor } from "src/theme/utils";
import { Maybe } from "true-myth";

const Number = styled(Box)<{ color: ThemeColor }>`
    padding: 0.2rem 0;
    width: 3.5rem;
    text-align: center;
    border-radius: 0.4rem;
    background-color: ${({ color }) => getAlphaColor(color, 0.1)};
`;

interface Props {
    event: CalendarEvent;
    onComplete: () => void;
}

interface Details {
    label: string;
    color?: ThemeColor;
}

export const Counter = ({ event, onComplete }: Props) => {
    const counterDetailsMap: Record<CalendarEventId, Details> = {
        "allowlist-prelaunch": {
            label: "Allowlist deposits open in:",
        },
        "allowlist-open": {
            label: "Public deposits open in:",
        },
        "strategy-open": {
            label: "Deposits & withdrawals closing in:",
            color: ThemeColor.ACTION_CONFIRMATION,
        },
        "strategy-locked": {
            label: "Deposits & withdrawals open in:",
        },
    };

    return Maybe.of(counterDetailsMap[event.id]).mapOr(<></>, ({ label, color = ThemeColor.ACTION_WARNING }) => (
        <Flex
            justifyContent={{ base: "center", md: "space-between" }}
            p={"1rem 1.6rem"}
            borderRadius={"0.5rem"}
            color={color}
            bg={getAlphaColor(color, 0.1)}
            flexWrap={"wrap"}
            gap={"1rem"}
        >
            <Text width={{ base: "100%", md: "auto" }} textAlign={"center"}>
                {label}
            </Text>
            <Countdown
                date={event.endDate}
                renderer={({ days, hours, minutes, seconds, completed }: CountdownRenderProps) =>
                    !completed ? (
                        <HStack>
                            {days > 0 && (
                                <>
                                    <Number color={color}>{days}</Number>
                                    <Text>{`Day${days > 1 ? `s` : ``}`}</Text>
                                </>
                            )}
                            <Number color={color}>{String(hours).padStart(2, "0")}</Number>
                            <Text>:</Text>
                            <Number color={color}>{String(minutes).padStart(2, "0")}</Number>
                            <Text>:</Text>
                            <Number color={color}>{String(seconds).padStart(2, "0")}</Number>
                        </HStack>
                    ) : (
                        <></>
                    )
                }
                onComplete={onComplete}
            />
        </Flex>
    ));
};
