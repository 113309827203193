import { Theme } from "@chakra-ui/react";

const fonts: Partial<Theme["fonts"]> = {
    body: "Outfit, sans-serif",
    heading: "Outfit, sans-serif",
};

const fontSizes: Partial<Theme["fontSizes"]> = {
    xs: "1.35rem",
    sm: "1.475rem",
    md: "1.6rem",
    lg: "1.725rem",
    xl: "1.85rem",
    "2xl": "2.1rem",
    "3xl": "2.475rem",
    "4xl": "2.85rem",
    "5xl": "3.6rem",
    "6xl": "4.35rem",
    "7xl": "5.1rem",
    "8xl": "6.6rem",
    "9xl": "8.6rem",
};

export const typography = {
    fonts,
    fontSizes,
};
