import { List, ListItem, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { Card } from "src/uikit";
import { Heading } from "src/uikit/typography";

export const TermsPage = () => {
    return (
        <Card>
            <Stack spacing={4}>
                <Heading as={"h2"}>HEDGEFARM TERMS AND CONDITIONS</Heading>

                <Heading as={"h4"}>YIELD SERVICE</Heading>

                <Text>Last modified: 14 October 2022</Text>

                <Text fontWeight={"bold"}>THIS IS AN IMPORTANT DOCUMENT.</Text>
                <Text>
                    These Terms contain the terms and conditions for the provision and use of the HedgeFarm Virtual
                    Asset Yield Service (the “Service”), along with any electronic form or contract executed
                    therethrough.
                </Text>
                <Text>PLEASE READ IT CAREFULLY.</Text>
                <Text>IMPORTANT NOTICE</Text>
                <Text>
                    ACCESSING OR USING THE SERVICE MAY BE RESTRICTED BY LAW IN CERTAIN JURISDICTIONS AS OFFERING THE
                    SERVICE MAY BE SUBJECT TO LEGAL INVESTMENT LAWS AND REGULATIONS.
                </Text>

                <Text>
                    THE SERVICE IS NOT AVAILABLE TO ANYONE RESIDING OR OTHERWISE ESTABLISHED IN A JURISDICTION WHERE
                    VIRTUAL ASSETS AND/OR THEIR USE CONTRAVENES APPLICABLE LAWS AND/OR ARE DEEMED TO BE FINANCIAL
                    INSTRUMENTS.
                </Text>

                <Text>
                    PRIOR TO ACCESSING OR USING THE SERVICE YOU MUST INFORM YOURSELF OF AND OBSERVE ANY RESTRICTIONS
                    APPLICABLE TO YOU. A FAILURE TO COMPLY WITH APPLICABLE RESTRICTIONS MAY CONSTITUTE A VIOLATION BY
                    YOU OF THE LAWS AND REGULATIONS IN YOUR JURISDICTION.
                </Text>

                <Text>TERRITORIAL LIMITATION</Text>
                <Text>
                    THE SERVICE IS NOT AVAILABLE IN BELARUS, THE CENTRAL AFRICAN REPUBLIC, THE DEMOCRATIC REPUBLIC OF
                    CONGO, THE DEMOCRATIC PEOPLE’S REPUBLIC OF KOREA, THE CRIMEA REGION OF UKRAINE, CUBA, IRAN, LIBYA,
                    THE PEOPLE’S REPUBLIC OF CHINA, THE RUSSIAN FEDERATION, SOMALIA, SUDAN, SOUTH SUDAN, SYRIA, THE
                    UNITED STATES OF AMERICA, YEMEN AND ZIMBABWE.
                </Text>
                <Text>
                    THE SERVICE HAS NOT BEEN OR WILL NOT BE REGISTERED UNDER THE UNITED STATES SECURITIES ACT OF 1933,
                    AS AMENDED (THE “SECURITIES ACT”) OR WITH ANY SECURITIES REGULATORY AUTHORITY OF ANY STATE OR OTHER
                    JURISDICTION OF THE UNITED STATES. CONSEQUENTLY, THE SERVICE MAY NOT BE ACCESSED OR USED BY OR
                    OTHERWISE OFFERED, SOLD, DELIVERED OR TRANSFERRED WITHIN THE UNITED STATES OR TO OR FOR THE ACCOUNT
                    OR BENEFIT OF ANY U.S. PERSONS AS SUCH TERMS ARE DEFINED IN REGULATIONS UNDER THE SECURITIES ACT OR
                    TO ANY U.S. CITIZENS (AS DEFINED IN 8 U.S.C. SUBCHAPTER III) OR ANY U.S. PERSONS (AS DEFINED IN 17
                    C.F.R S230.902(K)
                </Text>

                <Text>Legal Agreement</Text>
                <Text>
                    These HedgeFarm Service Terms and Conditions (the “Terms”), constitute a legally binding agreement
                    by and between CyberGiraffe DMCC (“Company” or “we”) and you as a user of the Service (collectively
                    and as applicable, “you” and “your”). Company and You may be referred to throughout these Terms
                    collectively as the “Parties” or individually as a “Party”.
                </Text>

                <Text>
                    By accessing or using our Service, you accept and agree to be bound by and to comply with these
                    Terms (the “Agreement”). You are entering into a binding Agreement. If you do not agree to these
                    Terms, you must not access or use our Service. You must be able to form a legally binding contract
                    online either on behalf of a company or as an individual. Accordingly, you represent that: (a) if
                    you are agreeing to these Terms on behalf of a company or other legal entity, you have the legal
                    authority to bind the company or other legal entity to these Terms; and (b) you are at least 18
                    years old (or the age of majority where you reside, whichever is older), can form a legally binding
                    contract online, and have the full, right, power and authority to enter into and to comply with the
                    obligations under these Terms. You further represent that you are not a citizen, resident, or agent
                    of, or an entity organized, incorporated or doing business in, Belarus, Burundi, Crimea and
                    Sevastopol, Cuba, Democratic Republic of Congo, Iran, Iraq, Libya, North Korea, Somalia, Sudan,
                    Syria, Venezuela, Zimbabwe or any other country to which the United States, the United Kingdom, the
                    European Union or any of its member states or the United Nations or any of its member states
                    (collectively, the “Major Jurisdictions”) embargoes goods or imposes similar sanctions (such
                    embargoed or sanctioned territories, collectively, the “Restricted Territories”); (ii) you are not,
                    and do not directly or indirectly own or control, and have not received any assets from, any
                    blockchain address that is, listed on any sanctions list or equivalent maintained by any of the
                    Major Jurisdictions (such sanctions-listed persons, collectively, “Sanctions Lists Persons”); and
                    (iii) you do not intend to transact in or with any Restricted Territories or Sanctions List Persons,
                    or where your use of the Protocol (as defined below) and Service would be illegal or otherwise
                    violate any applicable law. You further represent that your access and use of the Protocol or
                    Service will fully comply with all applicable laws and regulations, and that you will not access or
                    use the Protocol or Service to conduct, promote, or otherwise facilitate any illegal activity.
                </Text>

                <Text>
                    By accessing or using the Protocol, you agree that HedgeFarm does not provide execution or clearing
                    services of any kind and is not responsible for the execution or clearing of transactions automated
                    through HedgeFarm software. Furthermore, by accessing or using our Service, you acknowledge and
                    accept that HedgeFarm does not operate an exchange or a trading facility, act as a bank, as a
                    custodian, as a securities firm or as a broker or dealer, and it does under no circumstances provide
                    investment, tax, or legal advice to its Users.
                </Text>

                <Heading as={"h5"}>1. Definitions</Heading>

                <Text>
                    For the purpose of these Terms, the following capitalized terms shall have the following meanings:
                </Text>
                <List pl={6} spacing={2}>
                    <ListItem>
                        1.1. “Affiliate” means, with respect to a party to these Terms, any legal entity that, directly
                        or indirectly controls, is controlled by, or is under common control with such party.
                    </ListItem>
                    <ListItem>
                        1.2. “Applicable Law” means any domestic or foreign law, rule, statute, regulation, by-law,
                        order, protocol, code, decree, or other directive, requirement or guideline, published or in
                        force which applies to or is otherwise intended to govern or regulate any person, property,
                        transaction, activity, event or other matter, including any rule, order, judgment, directive or
                        other requirement or guideline issued by any Governmental Authority having jurisdiction over the
                        Company, you, the Protocol or the Service, or as otherwise duly enacted, enforceable by law, the
                        common law or equity.
                    </ListItem>
                    <ListItem>
                        1.3. “Applicable Network” means each respective blockchain network supported by the Protocol to
                        perform any of the Services, as displayed on the Interface at any given time.
                    </ListItem>
                    <ListItem>
                        1.4. “Applicable Network Token” means a utility token that may be used to purchase computational
                        resources to run decentralized applications or perform actions on the Applicable Network.
                    </ListItem>
                    <ListItem>
                        1.5. “Wallet Address” means the unique public key virtual asset identifier that points to an
                        Applicable Network-compatible wallet to which Applicable Network tokens may be sent or stored.
                    </ListItem>
                    <ListItem>
                        1.6. “HedgeFarm Smart Contracts” means the proprietary Smart Contracts (as defined below)
                        developed by HedgeFarm.
                    </ListItem>
                    <ListItem>
                        1.7. “Governmental Authority” includes any domestic or foreign federal, provincial or state,
                        municipal, local or other governmental, regulatory, judicial or administrative authority.
                    </ListItem>
                    <ListItem>
                        1.8. “Interface” means the HedgeFarm Website located at https://hedgefarm.finance, and all
                        associated sites linked thereto by the Company and its Affiliates.
                    </ListItem>
                    <ListItem>
                        1.9. “Procol” means the Interface taken together with the underlying HedgeFarm Smart Contracts
                        connected to it, which includes, for certainty, HedgeFarm’s decentralized application layer on
                        the Applicable Network.
                    </ListItem>
                    <ListItem>1.10. “Service” has the meaning set out in Section 2.1.</ListItem>
                    <ListItem>
                        1.11. “Smart Contract” means a computer program or a transaction protocol that is intended to
                        automatically execute, control or document legally relevant events and actions which exist
                        across a distributed, decentralized blockchain network.
                    </ListItem>
                    <ListItem>
                        1.12. “Vault” means a HedgeFarm Smart Contract paired to a particular Service, as displayed on
                        the Interface.
                    </ListItem>
                    <ListItem>
                        1.13. “Virtual Asset” means a digital representation of value that can be digitally traded, or
                        transferred, and can be used for payment or investment purposes. Virtual Assets do not include
                        digital representations of securities and other financial assets.
                    </ListItem>
                </List>

                <Heading as={"h5"}>2. The Service</Heading>

                <List pl={6} spacing={2}>
                    <ListItem>
                        2.1. The Service. The primary purpose of the Service is to enable users to interact with
                        third-party decentralized finance Smart Contracts and hedge against risk or gain exposure to
                        risk via the Protocol (the “Service”).
                    </ListItem>
                    <ListItem>
                        2.2. Gas Charges. The Service involves the use of various blockchain networks, which may require
                        that you pay a fee, commonly known as “Gas Charges,” for the computational resources required to
                        perform a transaction on the Applicable Network. You acknowledge and agree that HedgeFarm has no
                        control over: (a) any Applicable Network transactions; (b) the method of payment of any Gas
                        Charges; or (c) any actual payments of Gas Charges. Accordingly, You must ensure that you have a
                        sufficient balance of Applicable Network Tokens stored in your Wallet Address to complete any
                        transaction on the Applicable Network before initiating such Applicable Network transaction. We
                        will make reasonable efforts to notify you of any Gas Charges before initiating any Service that
                        requires the use of the Applicable Network.
                    </ListItem>
                    <ListItem>
                        2.3. Conditions and Restrictions. We may, at any time and in our sole discretion, restrict your
                        access to, or otherwise impose conditions or restrictions upon your use of, the Service, with or
                        without prior notice.
                    </ListItem>
                    <ListItem>
                        2.4. Your Responsibilities. As a condition to accessing or using the Service, you shall:
                    </ListItem>
                    <ListItem>
                        <List pl={6} spacing={2}>
                            <ListItem>
                                (a) only use the Service for lawful purposes and in accordance with these Terms;
                            </ListItem>
                            <ListItem>
                                (b) ensure that, at all times, all information that you provide on the Interface is
                                current, complete and accurate; and
                            </ListItem>
                            <ListItem>(c) maintain the security and confidentiality of your Wallet Address.</ListItem>
                        </List>
                    </ListItem>
                    <ListItem>
                        2.5. Unacceptable Use or Conduct. As a condition to accessing or using the Service, you will
                        not:
                    </ListItem>
                    <ListItem>
                        <List pl={6} spacing={2}>
                            <ListItem>
                                (a) violate any Applicable Law, including, without limitation, any relevant and
                                applicable anti-money laundering and anti-terrorist financing laws, such as the Bank
                                Secrecy Act, each as may be amended;
                            </ListItem>
                            <ListItem>
                                (b) infringe on or misappropriate any contract, intellectual property or other
                                third-party right, or commit a tort while using the Service;
                            </ListItem>
                            <ListItem>
                                (c) use the Service in any manner that could interfere with, disrupt, negatively affect,
                                or inhibit other users from fully enjoying the Service, or that could damage, disable,
                                overburden, or impair the functioning of the Service in any manner;
                            </ListItem>
                            <ListItem>
                                (d) attempt to circumvent any content filtering techniques or security measures that
                                HedgeFarm employs on the Protocol, or attempt to access any service or area of the
                                Protocol that you are not authorized to access;
                            </ListItem>
                            <ListItem>
                                (e) use the Service to pay for, support, or otherwise engage in any illegal gambling
                                activities, fraud, money-laundering, or terrorist activities, or other illegal
                                activities;
                            </ListItem>
                            <ListItem>
                                (f) use any robot, spider, crawler, scraper, or other automated means or interface not
                                provided by us, to access the Protocol or to extract data;
                            </ListItem>
                            <ListItem>
                                (g) introduce any malware, virus, Trojan horse, worm, logic bomb, drop-dead device,
                                backdoor, shutdown mechanism or other harmful material into the Protocol;
                            </ListItem>
                            <ListItem>(h) provide false, inaccurate, or misleading information;</ListItem>
                            <ListItem>
                                (i) post content or communications on the Interface that are, in our sole discretion,
                                libelous, defamatory, profane, obscene, pornographic, sexually explicit, indecent, lewd,
                                vulgar, suggestive, harassing, hateful, threatening, offensive, discriminatory, bigoted,
                                abusive, inflammatory, fraudulent, deceptive or otherwise objectionable;
                            </ListItem>
                            <ListItem>
                                (j) post content on the Interface containing unsolicited promotions, political
                                campaigning, or commercial messages or any chain messages or user content designed to
                                deceive or trick the user of the Service;
                            </ListItem>
                            <ListItem>
                                (k) use the Protocol from a jurisdiction that we have, in our sole discretion, or a
                                relevant Governmental Authority has determined is a jurisdiction where the use of the
                                Protocol or Service is prohibited; or
                            </ListItem>
                            <ListItem>
                                (l) encourage or induce any third party to engage in any of the activities prohibited
                                under this Section 2.5.
                            </ListItem>
                        </List>
                    </ListItem>
                    <ListItem>
                        2.6. Your Content. You hereby grant to us a royalty-free, fully paid-up, sublicensable,
                        transferable, perpetual, irrevocable, non-exclusive, worldwide license to use, copy, modify,
                        create derivative works of, display, perform, publish and distribute, in any form, medium or
                        manner, any content that is available via the Protocol and results of your use of the Service
                        (collectively, “Your Content”), including, without limitation, for promoting HedgeFarm (or its
                        Affiliates), the Service. You represent and warrant that: (a) you own your Content or have the
                        right to grant the rights and licenses in these Terms; and (b) your Content and our use of your
                        Content, as licensed herein, does not and will not violate, misappropriate or infringe on any
                        third party’s rights.
                    </ListItem>
                </List>

                <Heading as={"h5"}>3. No Fiduciary Duties</Heading>
                <List pl={6} spacing={2}>
                    <ListItem>
                        3.1. This Agreement is not intended to, and does not, create or impose any fiduciary duties on
                        us. To the fullest extent permitted by law, you acknowledge and agree that we owe no fiduciary
                        duties or liabilities to you or any other party, and that to the extent any such duties or
                        liabilities may exist at law or in equity, those duties and liabilities are hereby irrevocably
                        disclaimed, waived, and eliminated. You further agree that the only duties and obligations that
                        we owe you are those set out expressly in this Agreement. Neither our communications nor any
                        information that we provide to you is intended as, or shall be considered or construed as,
                        financial and / or investment advice.
                    </ListItem>

                    <ListItem>
                        3.2. The Company is not a party to any transaction on the blockchain networks underlying the
                        Service; we do not have possession, custody or control over any Virtual Assets appearing on the
                        Service; and we do not have possession, custody, or control over any user’s Virtual Assets. You
                        understand that when you interact with the HedgeFarm Smart Contracts, you retain control over
                        your Virtual Assets at all times. The private key associated with the wallet address from which
                        you transfer Virtual Assets or the private key associated is the only private key that can
                        control the Virtual Assets you transfer into the smart contracts. You alone are responsible for
                        securing your private keys. We do not have access to your private keys. Due to the non-custodial
                        and decentralized nature of the technology, you acknowledge and agree that we are not
                        intermediaries, brokers, agents, advisors, or custodians.
                    </ListItem>
                </List>

                <Heading as={"h5"}>4. HedgeFarm Website Terms</Heading>
                <List pl={6} spacing={2}>
                    <ListItem>
                        4.1. The access and use of https://hedgefarm.finance (the “HedgeFarm Website”) is subject to the
                        separate terms of the HedgeFarm Website. You further acknowledge that you have carefully read
                        and have accepted the Terms of Use located on the HedgeFarm Website (the “HedgeFarm Website
                        Terms”) because the HedgeFarm Website Terms govern Your use of the HedgeFarm Website. If there
                        is a conflict between the HedgeFarm Website Terms and these Terms with respect to the Service,
                        these Terms shall prevail.
                    </ListItem>
                    <ListItem>
                        4.2. As a condition of using the HedgeFarm Website, and any time you provide your information
                        (including personal information (also known as personally identifiable information or personal
                        data) to Company or that Company collects from you when using the HedgeFarm Website, if and when
                        applicable (including information collected automatically by the use of cookies or other similar
                        technologies), you agree to the collection, use, storage, sharing (i.e., by way of example only,
                        with Company, its affiliates, and with third-party partners, service providers, etc.), transfer,
                        and processing of your information by Company for the purposes of providing the HedgeFarm
                        Website and the services available therein, to administer the Service, and to comply with
                        applicable laws, rules, and regulations.
                    </ListItem>
                </List>

                <Heading as={"h5"}>5. Proprietary Rights</Heading>

                <List pl={6} spacing={2}>
                    <ListItem>
                        5.1. Ownership of Service; License to the Service. Excluding any third-party software that the
                        Protocol or Service incorporate, as between you and HedgeFarm, HedgeFarm owns the Protocol,
                        including all technology, content, and other materials used, displayed or provided on the
                        Interface or in connection with the Protocol (including all intellectual property rights
                        subsisting therein). Provided that you are eligible, HedgeFarm hereby grants you a single,
                        personal, limited license to access and use the Protocol. This license is non-exclusive,
                        non-transferable, and freely revocable by HedgeFarm at any time without notice or cause. Use of
                        the Interface, Protocol, Service or their contents for any purpose not expressly permitted by
                        this Agreement is strictly prohibited.
                    </ListItem>
                    <ListItem>
                        5.2. Trademarks. Any of HedgeFarm’s product or service names, logos, and other marks used in the
                        Protocol or as a part of the Service, including HedgeFarm’s name and logo are trademarks owned
                        by HedgeFarm, its Affiliates or its applicable licensors. You may not copy, imitate or use them
                        without HedgeFarm’s (or the applicable licensor’s) prior written consent.
                    </ListItem>
                </List>

                <Heading as={"h5"}>6. Changes, Suspension, Termination</Heading>
                <List pl={6} spacing={2}>
                    <ListItem>
                        6.1. Changes to Service. We may, at our sole discretion, from time to time and with or without
                        prior notice to you, modify, suspend or disable, temporarily or permanently, the Service, in
                        whole or in part, for any reason whatsoever, including, but not limited to, as a result of a
                        security incident.
                    </ListItem>
                    <ListItem>
                        6.2. No Liability. We will not be liable for any losses suffered by you resulting from any
                        modification to the Service or from any suspension or termination, for any reason, of your
                        access to all or any portion of the Protocol or the Service.
                    </ListItem>
                    <ListItem>
                        6.3. Survival. The following sections will survive any termination of your access to the
                        Protocol or the Service, regardless of the reasons for its expiration or termination, in
                        addition to any other provision which by law or by its nature should survive: Sections 3, 5, 7,
                        9, 12, 13 and 14.
                    </ListItem>
                </List>

                <Heading as={"h5"}>7. Assumption of Risk</Heading>

                <Text>
                    By accessing and using the Service, you represent that you understand the inherent risks associated
                    with using cryptographic and blockchain-based systems, and that you have a working knowledge of the
                    usage and intricacies of Virtual Assets. You further understand that the markets for these Virtual
                    Assets are highly volatile due to factors including (but not limited to) adoption, speculation,
                    technology, security, and regulation. You acknowledge that the cost and speed of transacting with
                    cryptographic and blockchain-based systems such as the Applicable Network are variable and may
                    increase dramatically at any time. You further acknowledge the risk that your Virtual Assets may
                    lose some or all of their value while they are supplied to the Service. Use of the Protocol involves
                    the use of the Applicable Network, which may require that you pay Gas Charges, as described Section
                    2.2. above. You acknowledge and agree that we have no control over: (a) any Applicable Network
                    transactions; (b) the method of payment of any Gas Charges; or (c) any actual payments of Gas
                    Charges. Accordingly, you must ensure that you have a sufficient balance of Applicable Network
                    Tokens stored at your Wallet Address to complete any transaction on the Applicable Network before
                    initiating such Applicable Network transaction. You represent and warrant that you have the
                    knowledge, experience, understanding, professional advice and information to make your own
                    evaluation of the merits, risks and applicable compliance requirements under Applicable Law of
                    engaging in transactions through the Service. You acknowledge and understand that the Protocol
                    allocates your Virtual Assets to third-party decentralized web protocols for various purposes and
                    that we are not responsible or liable for these transactions or the operation of these third-party
                    protocols. You further acknowledge that we are not responsible for any of these variables or risks
                    and cannot be held liable for any resulting losses that you experience while accessing or using the
                    Protocol or the Service. You further acknowledge that you are solely responsible for determining
                    whether any transaction placed through the Protocol, is appropriate, suitable, adapted to your
                    financial situation, personal objectives, financial circumstances, and risk tolerance. You should
                    consult a legal, financial or tax professional regarding your specific situation before engaging in
                    any transaction through the Protocol . We are not responsible for the operation of the Protocol or
                    any underlying Smart Contracts which the Protocol links to, and we make no guarantee of their
                    functionality, security, or availability. Accordingly, you understand and agree to assume full
                    responsibility for all of the risks of accessing and using the Service and interacting with the
                    Protocol.
                </Text>

                <Heading as={"h5"}>8. Third-Party Resources and Promotions</Heading>

                <Text>
                    The Protocol may contain references or links to third-party resources, including (but not limited
                    to) information, materials, products, or services, that we do not own or control. In addition, third
                    parties may offer promotions related to your access and use of the Protocol or Service. We do not
                    endorse or assume any responsibility for any such resources or promotions. If you access any such
                    resources or participate in any such promotions, you do so at your own risk, and you understand that
                    this Agreement does not apply to your dealings or relationships with any third parties. You
                    expressly relieve us of any and all liability arising from your use of any such resources or
                    participation in any such promotions. You acknowledge that the Service links to certain open-source
                    components and that your use of the Protocol and the Service is subject to, and you will comply with
                    any, applicable open-source licenses that govern any such open-source components (collectively,
                    “Open-Source Licenses”). Without limiting the generality of the foregoing, you may not: (a) resell,
                    lease, lend, share, distribute or otherwise permit any third party to use the Protocol; (b) use the
                    Protocol for time-sharing or service bureau purposes; or (c) otherwise use the Protocol or Service
                    in a manner that violates any license hereunder.
                </Text>

                <Heading as={"h5"}>9. Indemnification</Heading>

                <Text>
                    You will defend, indemnify, and hold harmless us, our Affiliates, and our and our Affiliates’
                    respective shareholders, members, directors, officers, employees, attorneys, agents,
                    representatives, suppliers and contractors (collectively, “Indemnified Parties”) from any claim,
                    demand, lawsuit, action, proceeding, investigation, liability, damage, loss, cost or expense,
                    including without limitation reasonable attorneys’ fees, arising out of or relating to (a) your use
                    of, or conduct in connection with, the Service; (b) Applicable Network assets associated with your
                    Wallet Address; (c) any feedback or user content you provide to the Protocol, if any; (d) your
                    violation of these Terms; or (e) your infringement or misappropriation of the rights of any other
                    person or entity. If you are obligated to indemnify any Indemnified Party, HedgeFarm (or, at its
                    discretion, the applicable Indemnified Party) will have the right, in its sole discretion, to
                    control any action or proceeding and to determine whether HedgeFarm wishes to settle, and if so, on
                    what terms.
                </Text>

                <Heading as={"h5"}>10. Disclosures, Disclaimers</Heading>

                <Text>
                    HedgeFarm is a developer of software. HedgeFarm does not operate a Virtual Asset or derivatives
                    exchange platform or offer trade execution, custody or clearing services and therefore has no
                    oversight, involvement, or control with respect to your transactions. All transactions between users
                    of the Protocol are executed peer-to-peer directly between the users’ virtual wallets through a
                    Smart Contract. As a user of HedgeFarm, you declare that you are not a citizen or resident of any
                    jurisdiction in which either the use of the Service, exchange, purchase, receipt, or holding of any
                    Virtual Assets is prohibited, restricted, curtailed, hindered, impaired or otherwise adversely
                    affected by any Applicable Laws;
                </Text>

                <Heading as={"h5"}>11. No Warranties</Heading>

                <Text>
                    The Service is provided on an “AS IS” and “AS AVAILABLE” basis. To the fullest extent permitted by
                    law, we disclaim any representations and warranties of any kind, whether express, implied, or
                    statutory, including (but not limited to) the warranties of merchantability and fitness for a
                    particular purpose. You acknowledge and agree that your use of the Protocol or Service is at your
                    own risk. We do not represent or warrant that access to the Protocol or Service will be continuous,
                    uninterrupted, timely, or secure; that the information contained in the Protocol or Service will be
                    accurate, reliable, complete, or current; or that the Protocol or Service will be free from errors,
                    defects, viruses, or other harmful elements. No advice, information, or statement that we make
                    should be treated as creating any warranty concerning the Protocol or Service. We do not endorse,
                    guarantee, or assume responsibility for any advertisements, offers, or statements made by third
                    parties concerning the Protocol or Service.
                </Text>

                <Heading as={"h5"}>12. Taxes</Heading>

                <Text>
                    You are responsible for your taxes and duties. Users bear sole responsibility for paying any and all
                    taxes, duties, and assessments now or hereafter claimed or imposed by any governmental authority
                    associated with their use of the Service, and/or payable as the result of using and/or exploiting
                    any Virtual Assets and interacting with smart contracts. Blockchain-based transactions are novel,
                    and their tax treatment may be uncertain in your place of residence.
                </Text>

                <Heading as={"h5"}>13. Exclusion of consequential and related damages</Heading>

                <Text>
                    In no event shall we (together with our Affiliates, including our and our Affiliates’ respective
                    shareholders, members, directors, officers, employees, attorneys, agents, representatives, suppliers
                    or contractors) be liable for any incidental, indirect, special, punitive, consequential or similar
                    damages or liabilities whatsoever (including, without limitation, damages for loss of data,
                    information, revenue, goodwill, profits or other business or financial benefit) arising out of or in
                    connection with the Protocol or the Service (and any of their content and functionality), any
                    execution or settlement of a transaction, any performance or non-performance of the Service, your
                    Applicable Network Token, or any other product, service or other item provided by or on behalf of
                    us, whether under contract, tort (including negligence), civil liability, statute, strict liability,
                    breach of warranties, or under any other theory of liability, and whether or not we have been
                    advised of, knew of or should have known of the possibility of such damages and notwithstanding any
                    failure of the essential purpose of these Terms or any limited remedy hereunder nor is HedgeFarm in
                    any way responsible for the execution or settlement of transactions between users of HedgeFarm
                    software.
                </Text>

                <Heading as={"h5"}>14. Limitation of Liability</Heading>

                <Text>
                    In no event shall we (together with our Affiliates, including our and our Affiliates’ respective
                    shareholders, members, directors, officers, employees, attorneys, agents, representatives, suppliers
                    or contractors) be liable for any incidental, indirect, special, punitive, consequential or similar
                    damages or liabilities whatsoever (including, without limitation, damages for loss of data,
                    information, revenue, goodwill, profits or other business or financial benefit) arising out of or in
                    connection with the Protocol and the Service (and any of their content and functionality), any
                    execution or settlement of a transaction, any performance or non-performance of the Service, your
                    Applicable Network Token, or any other product, service or other item provided by or on behalf of
                    us, whether under contract, tort (including negligence), civil liability, statute, strict liability,
                    breach of warranties, or under any other theory of liability, and whether or not we have been
                    advised of, knew of or should have known of the possibility of such damages and notwithstanding any
                    failure of the essential purpose of these Terms or any limited remedy hereunder nor is HedgeFarm in
                    any way responsible for the execution or settlement of transactions between users of HedgeFarm
                    software.
                </Text>

                <Heading as={"h5"}>15. Amendments of these Terms</Heading>

                <Text>
                    We reserve the right, in our sole discretion, to amend these Terms at any time, as the Company deems
                    necessary or desirable. If we make any material changes restricting or affecting in any way your
                    rights, we will provide you with notice of such changes, such as by sending an email, providing a
                    notice through our Service or updating the date at the top of these Terms. Unless we say otherwise
                    in our notice, any and all such modifications are effective immediately, and your continued use of
                    our Service after we provide such notice will confirm your acceptance of the changes. If you do not
                    agree to the amended Terms, you must stop using our Service following the date on which such amended
                    Terms are published.
                </Text>

                <Heading as={"h5"}>16. Termination and Cancellation</Heading>

                <Text>
                    This Agreement is effective unless and until terminated by either you or us. You may terminate your
                    Agreement with us at any time by ceasing all access to the Protocol or the Service. If, in our sole
                    judgment, you fail, or we suspect that you have failed, to comply with any term or provision of the
                    Agreement (including without limitation any provision of these Terms), we reserve the right to
                    terminate our Agreement with you and deny you access to the Service. We further reserve the right to
                    restrict your access to the Protocol or to stop providing you with all or a part of the Service at
                    any time and for no reason, including, without limitation, if we reasonably believe: (a) your use of
                    the Service exposes us to risk or liability; (b) you are using the Service for unlawful purposes; or
                    (c) it is not commercially viable to continue providing you with our Service. All of these are in
                    addition to any other rights and remedies that may be available to us, whether in equity or at law,
                    all of which we expressly reserve.
                </Text>

                <Text>
                    WE RESERVE THE RIGHT TO MODIFY THE SERVICE AT ANY TIME, BUT WE HAVE NO OBLIGATION TO UPDATE THE
                    SERVICE. YOU AGREE THAT IT IS YOUR RESPONSIBILITY TO MONITOR CHANGES TO THE SERVICE THAT MAY AFFECT
                    YOU. YOU AGREE THAT WE MAY REMOVE THE SERVICE AND/OR ANY CONTENT THEREON FOR INDEFINITE PERIODS OF
                    TIME OR CANCEL THE SERVICE AT ANY TIME, WITHOUT NOTICE TO YOU.
                </Text>

                <Heading as={"h5"}>17. Severability</Heading>

                <Text>
                    If any provision of the Agreement (including, without limitation, these Terms) is determined to be
                    unlawful, void, or unenforceable, such provision shall nonetheless be enforceable to the fullest
                    extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from
                    the Agreement. Such determination shall not affect the validity and enforceability of any other
                    remaining provisions.
                </Text>

                <Heading as={"h5"}>18. Assignment</Heading>

                <Text>
                    The Agreement (including, without limitation, these Terms) may be assigned without your prior
                    consent to any Company Affiliate, or to its successors in the interest of any business associated
                    with the Service provided by us. You may not assign or transfer any rights or obligations under the
                    Agreement without our prior written consent.
                </Text>

                <Heading as={"h5"}>19. Entire Agreement</Heading>

                <Text>
                    The Agreement (including, without limitation, these Terms, and the HedgeFarm Website Terms) and any
                    policies or operating rules posted by us on the Service constitute the entire agreement and
                    understanding between you and us and govern your use of the Service, superseding any prior or
                    contemporaneous agreements, communications, and proposals, whether oral or written, between you and
                    us (including, but not limited to, any prior versions of these Terms). Any failure by us to exercise
                    or enforce any right or provision of the Agreement (including, without limitation, these Terms)
                    shall not constitute a waiver of such right or provision.
                </Text>

                <Heading as={"h5"}>20. Governing Law and Dispute Resolution</Heading>

                <List pl={6} spacing={2}>
                    <ListItem>
                        20.1. Governing Law. These Terms shall be governed by, and construed in accordance with, the
                        laws of the United Arab Emirates.
                    </ListItem>
                    <ListItem>
                        20.2. Dispute Resolution. Any dispute arising out of or in connection with these Terms,
                        including any question regarding its existence, validity or termination, shall be referred to
                        and finally be resolved by the competent courts of the United Arab Emirates.
                    </ListItem>
                </List>

                <Heading as={"h5"}>21. Electronic Notices</Heading>

                <Text>
                    You consent to receive all communications, agreements, documents, receipts, notices, and disclosures
                    electronically (collectively, our “Communications”) that we provide in connection with these Terms
                    or any Service. You agree that we may provide our Communications to you by posting them on the
                    Interface or by emailing them to you at the email address you provide in connection with using the
                    Service, if any. You should maintain copies of our Communications by printing a paper copy or saving
                    an electronic copy. You may also contact our support team to request additional electronic copies of
                    our Communications by filing a support request at team@hedgefarm.finance
                </Text>
            </Stack>
        </Card>
    );
};
