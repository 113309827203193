import { connectorsForWallets, getDefaultWallets } from "@rainbow-me/rainbowkit";
import { Chain, configureChains, createClient } from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import { coreWallet } from "./wallet-connectors/coreWallet";

const avalancheChain: Chain = {
    id: 43_114,
    name: "Avalanche",
    network: "avalanche",
    nativeCurrency: {
        decimals: 18,
        name: "Avalanche",
        symbol: "AVAX",
    },
    rpcUrls: {
        public: { http: ["https://api.avax.network/ext/bc/C/rpc"] },
        default: { http: ["https://api.avax.network/ext/bc/C/rpc"] },
    },
    blockExplorers: {
        default: { name: "SnowTrace", url: "https://snowtrace.io" },
        etherscan: { name: "SnowTrace", url: "https://snowtrace.io" },
    },
    testnet: false,
};

const { chains, provider } = configureChains([avalancheChain], [publicProvider()]);

const { wallets: defaultWallets } = getDefaultWallets({
    appName: "HedgeFarm",
    chains,
});

const connectors = connectorsForWallets([
    ...defaultWallets,
    {
        groupName: "Other",
        wallets: [coreWallet({ chains })],
    },
]);

export { chains };

export const client = createClient({
    autoConnect: true,
    connectors,
    provider,
});
