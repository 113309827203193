import { Image, ImageProps } from "@chakra-ui/react";
import React from "react";

import Gauge1 from "../assets/Gauge-1.png";
import Gauge2 from "../assets/Gauge-2.png";
import Gauge3 from "../assets/Gauge-3.png";
import Gauge4 from "../assets/Gauge-4.png";
import Gauge5 from "../assets/Gauge-5.png";
import Gauge6 from "../assets/Gauge-6.png";
import Gauge7 from "../assets/Gauge-7.png";
import Gauge8 from "../assets/Gauge-8.png";
import Gauge9 from "../assets/Gauge-9.png";
import Gauge10 from "../assets/Gauge-10.png";

const gaugeMap = {
    1: Gauge1,
    2: Gauge2,
    3: Gauge3,
    4: Gauge4,
    5: Gauge5,
    6: Gauge6,
    7: Gauge7,
    8: Gauge8,
    9: Gauge9,
    10: Gauge10,
};

export type GaugeValue = keyof typeof gaugeMap;

interface Props extends ImageProps {
    value: GaugeValue;
}

export const Gauge = ({ value, ...props }: Props) => <Image src={gaugeMap[value]} {...props} />;
