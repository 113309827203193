import { Box, Center, Flex, HStack, Spinner, Stack, Text } from "@chakra-ui/react";
import styled from "@emotion/styled";
import React from "react";
import { shallow } from "zustand/shallow";
import { useStrategyStatsStore } from "src/stores/alphaVaultStatsStore";
import { AssetSymbol } from "src/theme";
import { Asset, Card, CardProps, Stat, StatProps, TooltipLabel } from "src/uikit";
import { Heading } from "src/uikit/typography";
import { formatNumber } from "src/utils/format";
import { CapProgress } from "./components/CapProgress";
import { Counter } from "./components/Counter";
import { Maybe } from "true-myth";
import { CalendarEvent } from "src/rest/hedgeFarm";
import { Stats } from "src/uikit/Stats";

const Statistics = styled(Stats)`
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        margin-left: ${({ theme }) => `-${theme.semanticTokens.sizes.cardPadding}`};
        margin-bottom: ${({ theme }) => `-${theme.semanticTokens.sizes.cardPadding}`};
        margin-right: ${({ theme }) => `-${theme.semanticTokens.sizes.cardPadding}`};
    }

    & > div {
        padding: 1.7rem;
        padding-inline: ${({ theme }) => theme.semanticTokens.sizes.cardPadding};
        border-top-width: 1px;
    }
`;

interface Props extends CardProps {
    name: string;
    subtitle: string;
    symbol: AssetSymbol;
    averageApy: Maybe<number>;
    currentEvent: Maybe<CalendarEvent>;
    showCounter?: boolean;
    statistics: StatProps[];
    apyLabel: string;
    apyExplanation: string;
}

export const StrategyHeader = ({
    name,
    subtitle,
    symbol,
    averageApy,
    currentEvent,
    showCounter = true,
    statistics,
    apyLabel,
    apyExplanation,
    ...props
}: Props) => {
    const [counterEvent, setCounterEvent] = React.useState<CalendarEvent | null>(null);
    const stats = useStrategyStatsStore(
        state => ({ cap: state.cap, tvl: state.totalValueLocked, depositToken: state.depositToken }),
        shallow,
    );

    React.useEffect(() => {
        setCounterEvent(currentEvent.unwrapOr(null));
    }, [currentEvent]);

    return (
        <Card {...props}>
            <Stack spacing={{ base: 4, sm: 6 }}>
                <Flex justifyContent={"space-between"} flexDirection={{ base: "column", sm: "row" }} gap={"1.6rem"}>
                    <HStack spacing={4}>
                        <Asset symbol={symbol} size={"lg"} />
                        <Stack>
                            <Text color={"gray.500"}>{subtitle}</Text>
                            <Heading as={"h2"}>{name}</Heading>
                        </Stack>
                    </HStack>
                    {averageApy
                        .map(apy => (
                            <Stat
                                key={apy}
                                label={apyLabel}
                                value={formatNumber(apy, { suffix: "%", maximumFractionDigits: 2 })}
                                headingSize={{ base: "2rem", sm: "3.8rem" }}
                                tooltipLabel={<TooltipLabel heading={apyLabel}>{apyExplanation}</TooltipLabel>}
                                textAlign={"right"}
                            />
                        ))
                        .unwrapOr(
                            <Center w={"7.2rem"} h={"7.2rem"}>
                                <Spinner />
                            </Center>,
                        )}
                </Flex>
                {counterEvent && showCounter && (
                    <Counter event={counterEvent} onComplete={() => setCounterEvent(null)} />
                )}
                {stats.cap.gt(0) && <CapProgress cap={stats.cap} tvl={stats.tvl} symbol={symbol} />}
                <Box>
                    <Statistics stats={statistics} />
                </Box>
            </Stack>
        </Card>
    );
};
