import React from "react";
import {
    ChartAlpha1,
    Execution,
    Link,
    Overview,
    Performance,
    StrategyDetails,
    StrategyExtras,
    StrategyHeader,
    TermsModal,
    WalletAlpha1,
} from "src/components";
import { Strategy, useCalendarData, useExecutionData, usePerformanceData, useStrategyClient } from "src/contracts";
import { useStrategyStatsStore } from "src/stores/alphaVaultStatsStore";
import { AssetSymbol } from "src/theme";
import { Column, Template } from "src/uikit/template";
import { Maybe } from "true-myth";
import { useAccount } from "wagmi";
import { Box, HStack, Text } from "@chakra-ui/react";
import { ReactComponent as LogoGmx } from "../components/Overview/assets/logo-gmx.svg";
import { getColorValue } from "../theme/utils";
import { ThemeColor } from "../theme/palette";
import { ReactComponent as LogoStargate } from "../components/Overview/assets/logo-stargate.svg";
import { ReactComponent as LogoAave } from "../components/Overview/assets/logo-aave.svg";
import { TooltipLabel } from "../uikit";

const STATS_REFRESH_INTERVAL = 10000;

interface Props<T extends Strategy> {
    strategy: T;
    strategySymbol: AssetSymbol;
    notice?: JSX.Element;
    allowlistEnabled?: boolean;
}

export const Alpha1Page = <T extends Strategy>({
    strategy,
    strategySymbol,
    notice,
    allowlistEnabled = false,
}: Props<T>) => {
    const client = useStrategyClient(strategy);
    const { address } = useAccount();
    const resetStats = useStrategyStatsStore(state => state.resetStats);
    const refreshStats = useStrategyStatsStore(state => state.refreshStats);
    const refreshStrategyStats = () => refreshStats(client, Maybe.of(address));
    const { currentEpoch, currentEvent, nextOpenEvent } = useCalendarData(strategy);
    const { performanceData } = usePerformanceData(strategy);
    const { executionData } = useExecutionData(strategy);

    React.useEffect(() => {
        resetStats();
        refreshStrategyStats();
        const interval = setInterval(refreshStrategyStats, STATS_REFRESH_INTERVAL);
        return () => {
            clearInterval(interval);
        };
    }, [client, address]);

    return (
        <>
            <TermsModal />
            {notice && <Box mb={{ base: "1.6rem", sm: "2rem", md: "2.6rem" }}>{notice}</Box>}
            <Template rowGap={{ base: "1.6rem", sm: "2rem", md: "2.6rem", xl: "3.2rem" }}>
                <Column width={{ base: "100%", lg: "58.4325%", xl: "57.4325%" }}>
                    <StrategyHeader
                        order={1}
                        name={"The CPPIooor"}
                        subtitle={`Alpha #1 (${strategy === Strategy.Alpha1TheCPPIooorV1 ? `V1` : `V2`})`}
                        symbol={strategySymbol}
                        averageApy={Maybe.of(performanceData?.averageApy)}
                        currentEvent={currentEvent}
                        showCounter={strategy !== Strategy.Alpha1TheCPPIooorV1}
                        statistics={[
                            {
                                label: "Risk",
                                value: "Low",
                                tooltipLabel: (
                                    <TooltipLabel heading={"Vault Risk"}>
                                        The overall risk of the vault. For more information please refer to the risk
                                        sheet.
                                    </TooltipLabel>
                                ),
                                icon: "generic.info",
                            },
                            {
                                label: "Lock-up",
                                value: "28 Days",
                                tooltipLabel: (
                                    <TooltipLabel heading={"Lock-up Period"}>
                                        Time during which your funds are locked in order to execute the strategy.
                                    </TooltipLabel>
                                ),
                                icon: "generic.locked",
                            },
                            {
                                label: "Protection",
                                value: "95%",
                                tooltipLabel: (
                                    <TooltipLabel heading={"Capital Protected"}>
                                        Percentage of capital protected during an epoch (28 days).
                                    </TooltipLabel>
                                ),
                                icon: "generic.protection",
                            },
                        ]}
                        apyLabel={"Year-To-Date APY"}
                        apyExplanation={
                            "Annual percentage yield since the start of the year (if no live data is available then backtested data will be used)."
                        }
                    />
                    <Execution data={Maybe.of(executionData)} order={2} />
                    <Overview
                        body={
                            <>
                                <Text>
                                    Alpha #1 - The Smart CPPIooor is a DeFi vault that helps you earn higher returns
                                    while protecting your capital. It uses a Constant Proportion Portfolio Insurance
                                    (CPPI) strategy that combines a passive yield strategy with an algorithmic trading
                                    strategy to generate excess returns.
                                </Text>
                                <Text mt={2}>
                                    This product is ideal for those looking for greater returns while also wanting
                                    predictability in case of market downturns.
                                </Text>
                            </>
                        }
                        tableContent={[
                            {
                                title: "Source of yield",
                                content: <Text>Yield Farming, Trading Gains</Text>,
                                tooltip: "The different yield-generation processes we use in the strategy.",
                            },
                            {
                                title: "Protocols",
                                content: (
                                    <HStack spacing={3}>
                                        <Link to={"https://gmx.io"} target={"_blank"}>
                                            <LogoGmx
                                                height={"2rem"}
                                                width={"6.9rem"}
                                                fill={getColorValue(ThemeColor.CONTENT)}
                                            />
                                        </Link>
                                        <Link to={"https://stargate.finance"} target={"_blank"}>
                                            <LogoStargate
                                                height={"2rem"}
                                                width={"7.9rem"}
                                                fill={getColorValue(ThemeColor.CONTENT)}
                                            />
                                        </Link>
                                        <Link to={"https://aave.com"} target={"_blank"}>
                                            <LogoAave
                                                height={"1.75rem"}
                                                width={"5rem"}
                                                fill={getColorValue(ThemeColor.CONTENT)}
                                            />
                                        </Link>
                                    </HStack>
                                ),
                                tooltip: "Protocols used to execute the strategy.",
                            },
                            {
                                title: "Investment Horizon",
                                content: <Text>4 to 6 months</Text>,
                                tooltip: "Time to consider to benefit from optimum returns of the strategy.",
                            },
                            {
                                title: "Epoch Length",
                                content: <Text>28 Days</Text>,
                                tooltip: "Period required to complete an entire cycle of the strategy.",
                            },
                            {
                                title: "Deposit/Withdrawal Period",
                                content: <Text>1 Day</Text>,
                                tooltip: "Number of days available to withdraw/deposit funds into the vault.",
                            },
                            {
                                title: "Lockup Period",
                                content: <Text>27 Days</Text>,
                                tooltip: "Time during which your funds are locked in order to execute the strategy.",
                            },
                            {
                                title: "Auto-Renew",
                                content: <Text>Active</Text>,
                                tooltip:
                                    "If you don’t withdraw at the end of an epoch your funds will automatically be reinvested into the next one.",
                            },
                        ]}
                        order={3}
                    />
                    <Performance
                        bodyText={
                            "This chart represents the vault performance between Oct 2018 and now with a $10k initial investment."
                        }
                        data={Maybe.of(performanceData)}
                        Chart={ChartAlpha1}
                        order={4}
                    />
                    <StrategyDetails order={5} />
                </Column>
                <Column width={{ base: "100%", lg: "38.1622%", xl: "37.1622%" }}>
                    <WalletAlpha1<T>
                        strategy={strategy}
                        currentEpoch={currentEpoch}
                        currentEvent={currentEvent}
                        nextOpenEvent={nextOpenEvent}
                        allowlistEnabled={allowlistEnabled}
                        order={2}
                    />
                    <StrategyExtras<T> strategy={strategy} order={3} />
                </Column>
            </Template>
        </>
    );
};
