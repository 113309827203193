import { Tag, TagLabel } from "@chakra-ui/react";
import React from "react";
import { ThemeColor } from "src/theme/palette";
import { Icon, IconName } from "src/uikit/icon";
import { Maybe } from "true-myth";

export type VaultStatus = "launchingSoon" | "allowlistOpen" | "open" | "locked" | "paused";

interface Props {
    status: VaultStatus;
}

interface Details {
    label: string;
    icon: IconName;
    color: ThemeColor;
    bgColor?: ThemeColor;
}

export const StatusCapsule = ({ status }: Props) => {
    const statusDetailsMap: Record<Props["status"], Details> = {
        launchingSoon: {
            label: "Launching Soon",
            icon: "generic.alarmClock",
            color: ThemeColor.ACTION_WARNING,
        },
        allowlistOpen: {
            label: "Open for Allowlist",
            icon: "generic.transact",
            color: ThemeColor.ACTION_CONFIRMATION,
        },
        open: {
            label: "Open",
            icon: "generic.transact",
            color: ThemeColor.ACTION_CONFIRMATION,
        },
        locked: {
            label: "Locked",
            icon: "generic.locked",
            color: ThemeColor.CONTENT,
            bgColor: ThemeColor.GENERAL,
        },
        paused: {
            label: "Paused",
            icon: "generic.alarmClock",
            color: ThemeColor.CONTENT,
            bgColor: ThemeColor.GENERAL,
        },
    };

    return Maybe.of(statusDetailsMap[status]).mapOr(<></>, ({ label, icon, color, bgColor }) => (
        <Tag variant={"capsule"} color={color} bg={bgColor}>
            <Icon name={icon} fill={color} w={"2rem"} h={"2rem"} mr={"0.8rem"} />
            <TagLabel>{label}</TagLabel>
        </Tag>
    ));
};
