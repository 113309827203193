import { Table, TableProps, Tbody, Td, Th, Thead, Tr, Text, HStack } from "@chakra-ui/react";
import React from "react";
import { ExecutionItem, ExecutionPositionId, ExecutionProtocolId } from "src/rest/hedgeFarm";
import { getColorValue } from "../../../theme/utils";
import { ThemeColor } from "../../../theme/palette";
import { Link } from "../../Link";
import { ReactComponent as LogoStargate } from "../assets/logo-stargate.svg";
import { ReactComponent as LogoGmx } from "../assets/logo-gmx.svg";
import { ReactComponent as LogoAave } from "../assets/logo-aave.svg";
import { Icon, IconName } from "src/uikit/icon";
import { formatNumber } from "src/utils/format";
import { startCase } from "lodash";

interface Props extends TableProps {
    data: ExecutionItem[];
}

export const PerformanceHistoryTable = ({ data, ...props }: Props) => {
    const renderProtocol = (protocol: ExecutionProtocolId): JSX.Element => {
        switch (protocol) {
            case "stargate":
                return (
                    <Link to={"https://stargate.finance"} target={"_blank"}>
                        <LogoStargate height={"2rem"} width={"7rem"} fill={getColorValue(ThemeColor.CONTENT)} />
                    </Link>
                );
            case "gmx":
                return (
                    <Link to={"https://gmx.io"} target={"_blank"}>
                        <LogoGmx height={"2rem"} width={"6.9rem"} fill={getColorValue(ThemeColor.CONTENT)} />
                    </Link>
                );
            case "aave":
                return (
                    <Link to={"https://aave.com"} target={"_blank"}>
                        <LogoAave height={"2rem"} width={"6rem"} fill={getColorValue(ThemeColor.CONTENT)} />
                    </Link>
                );
        }
    };

    const getPositionColor = (position: ExecutionPositionId): string => {
        switch (position) {
            case "long":
                return ThemeColor.ACTION_CONFIRMATION;
            case "short":
                return ThemeColor.ACTION_ERROR;
            default:
                return ThemeColor.CONTENT;
        }
    };

    return (
        <Table {...props}>
            <Thead>
                <Tr>
                    <Th>Protocol</Th>
                    <Th>Position</Th>
                    <Th>Asset</Th>
                    <Th display={{ base: "none", md: "table-cell" }}>Size</Th>
                    <Th textAlign={"end"} display={{ base: "none", xl: "table-cell" }}>
                        Entry price
                    </Th>
                </Tr>
            </Thead>
            <Tbody>
                {data.map(({ protocol, position, asset, assetCode, size, entryPrice }, index) => (
                    <Tr key={`${protocol}${index}`}>
                        <Td>{renderProtocol(protocol)}</Td>
                        <Td>
                            <Text color={getPositionColor(position)} fontWeight={"bold"}>
                                {startCase(position)}
                            </Text>
                        </Td>
                        <Td>
                            <HStack display="flex">
                                <Icon
                                    name={`crypto.${assetCode.toLowerCase()}` as IconName}
                                    fill={ThemeColor.ACTION_PRIMARY}
                                />
                                <Text>{asset}</Text>
                            </HStack>
                        </Td>
                        <Td display={{ base: "none", md: "table-cell" }}>
                            {formatNumber(size, { suffix: assetCode, joinSeparator: " " })}
                        </Td>
                        <Td textAlign={"end"} display={{ base: "none", xl: "table-cell" }}>
                            {entryPrice == 0 ? "-" : formatNumber(entryPrice, { prefix: "$", joinSeparator: " " })}
                        </Td>
                    </Tr>
                ))}
            </Tbody>
        </Table>
    );
};
