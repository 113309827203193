import {
    Center,
    Flex,
    Spinner,
    Stack,
    Tab,
    Table,
    TableContainer,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Tbody,
    Td,
    Tr,
} from "@chakra-ui/react";
import React from "react";
import { shallow } from "zustand/shallow";
import { usePromise } from "react-use-promise-matcher";
import { Strategy, TransactionError, useStrategyClient } from "src/contracts";
import { AppConfigContext } from "src/context";
import { ThemeColor } from "src/theme/palette";
import { Card, CardProps } from "src/uikit";
import { Heading } from "src/uikit/typography";
import { BigDecimal, BIG_ZERO } from "src/utils/bigDecimal";
import { formatNumber } from "src/utils/format";
import { Maybe } from "true-myth";
import { NoticeProps, TransactionForm } from "./components/TransactionForm";
import { ContractTransaction } from "ethers";
import { useAccount, useNetwork } from "wagmi";
import { useStrategyStatsStore } from "src/stores/alphaVaultStatsStore";
import { CalendarEvent } from "src/rest/hedgeFarm";
import { StatusCapsule, VaultStatus } from "./components/StatusCapsule";
import { AssetSymbol } from "src/theme";
import { noop } from "lodash";

interface Props<T extends Strategy> extends CardProps {
    strategy: T;
    symbol: AssetSymbol;
    currentEvent: Maybe<CalendarEvent>;
}

export const WalletAlpha2 = <T extends Strategy>({ strategy, symbol, currentEvent, ...props }: Props<T>) => {
    const { strategies } = React.useContext(AppConfigContext);
    const [vaultStatus, setVaultStatus] = React.useState<VaultStatus>();
    const [isDepositDisabled, setIsDepositDisabled] = React.useState(false);
    const [isWithdrawalDisabled, setIsWithdrawalDisabled] = React.useState(false);

    const { chain } = useNetwork();
    const { address, isConnected } = useAccount();

    const client = useStrategyClient<T>(strategy);
    const stats = useStrategyStatsStore(
        state => ({
            isLoading: state.isLoading,
            cap: state.cap,
            depositToken: state.depositToken,
            iouToken: state.iouToken,
            isPaused: state.isPaused,
            tvl: state.totalValueLocked,
            allowance: state.allowance,
            balance: state.balance,
            depositTokenBalance: state.depositTokenBalance,
            refreshStats: state.refreshStats,
        }),
        shallow,
    );

    const maybeAccount = React.useMemo(() => Maybe.of(address), [address]);
    const isVaultFull = React.useMemo(() => stats.cap.gt(BIG_ZERO) && stats.tvl.gte(stats.cap), [stats.tvl, stats.cap]);
    const isAllowlistOpen = React.useMemo(
        () => currentEvent.mapOr(false, ({ id }) => id === "allowlist-open"),
        [currentEvent],
    );

    React.useEffect(() => {
        Maybe.of(stats.isPaused).match({
            Just: isPaused => {
                const vaultStatus: VaultStatus = isAllowlistOpen ? "launchingSoon" : isPaused ? "paused" : "open";
                const areTransactionsDisabled = !isConnected || isAllowlistOpen || isPaused || !!chain?.unsupported;

                setVaultStatus(vaultStatus);
                setIsDepositDisabled(areTransactionsDisabled || isVaultFull);
                setIsWithdrawalDisabled(areTransactionsDisabled);
            },
            Nothing: noop,
        });
    }, [isConnected, isVaultFull, chain, stats]);

    const [approveResult, approveSpend] = usePromise<ContractTransaction, [string], TransactionError>(
        client.approveSpend,
    );

    const [depositResult, depositToStrategy] = usePromise<ContractTransaction, [string, BigDecimal], TransactionError>(
        client.deposit,
    );

    const [withdrawResult, withdrawFromStrategy] = usePromise<
        ContractTransaction,
        [string, BigDecimal],
        TransactionError
    >(client.withdraw);

    const getDepositNotice = React.useCallback((): NoticeProps | null => {
        switch (vaultStatus) {
            case "open":
                if (isVaultFull) {
                    return {
                        text: "The strategy cap has been reached. Deposits are no longer possible.",
                        color: ThemeColor.ACTION_WARNING,
                    };
                } else {
                    return null;
                }
            case "paused":
                return {
                    text: "The vault is currently rebalancing. You will be able to deposit or withdraw in a couple minutes.",
                    color: ThemeColor.ACTION_PRIMARY,
                };
            default:
                return null;
        }
    }, [vaultStatus, isVaultFull, stats.balance.shares]);

    const getWithdrawalNotice = React.useCallback((): NoticeProps | null => {
        switch (vaultStatus) {
            case "paused":
                return {
                    text: "The vault is currently rebalancing. You will be able to deposit or withdraw in a couple minutes.",
                    color: ThemeColor.ACTION_PRIMARY,
                };
            default:
                return null;
        }
    }, [vaultStatus, stats.isPaused]);

    return (
        <Card {...props}>
            {stats.isLoading ? (
                <Center h={"11.2rem"}>
                    <Spinner />
                </Center>
            ) : (
                <Stack spacing={4}>
                    <Flex justifyContent={"space-between"}>
                        <Heading as={"h4"}>Wallet</Heading>
                        {vaultStatus && <StatusCapsule status={vaultStatus} />}
                    </Flex>
                    {maybeAccount.isJust() && (
                        <TableContainer>
                            <Table variant={"info"}>
                                <Tbody>
                                    <Tr>
                                        <Td>Deployed Amount</Td>
                                        <Td color={ThemeColor.CONTENT} isNumeric>
                                            {formatNumber(stats.balance.amount.toNumber(), {
                                                suffix: stats.depositToken.symbol,
                                                joinSeparator: " ",
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 8,
                                            })}
                                        </Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                        </TableContainer>
                    )}
                    <Tabs variant={"solid-rounded"} isFitted>
                        <TabList>
                            {/*<Tab>Deposit</Tab>*/}
                            <Tab>Withdrawal</Tab>
                        </TabList>
                        <TabPanels>
                            {/*<TabPanel>*/}
                            {/*    <TransactionForm*/}
                            {/*        transactionType={"deposit"}*/}
                            {/*        availableAmount={stats.depositTokenBalance}*/}
                            {/*        availableAmountSymbol={symbol}*/}
                            {/*        allowance={stats.allowance}*/}
                            {/*        onTransaction={(amount: BigDecimal) =>*/}
                            {/*            maybeAccount.match({*/}
                            {/*                Just: account =>*/}
                            {/*                    stats.allowance.lt(amount)*/}
                            {/*                        ? approveSpend(account)*/}
                            {/*                        : depositToStrategy(account, amount),*/}
                            {/*                Nothing: noop,*/}
                            {/*            })*/}
                            {/*        }*/}
                            {/*        approveResult={approveResult}*/}
                            {/*        transactionResult={depositResult}*/}
                            {/*        isConnected={isConnected}*/}
                            {/*        isDisabled={isDepositDisabled}*/}
                            {/*        isLoading={approveResult.isLoading || depositResult.isLoading}*/}
                            {/*        minAmount={strategies[strategy].minDepositAmount}*/}
                            {/*        maxAmount={strategies[strategy].maxDepositAmount}*/}
                            {/*        capRemainingAmount={stats.cap.minus(stats.tvl)}*/}
                            {/*        notice={getDepositNotice()}*/}
                            {/*    />*/}
                            {/*</TabPanel>*/}
                            <TabPanel>
                                <TransactionForm
                                    transactionType={"withdrawal"}
                                    availableAmount={stats.balance.shares}
                                    availableAmountSymbol={stats.iouToken.symbol as AssetSymbol}
                                    onTransaction={(amount: BigDecimal) =>
                                        maybeAccount.match({
                                            Just: account => withdrawFromStrategy(account, amount),
                                            Nothing: noop,
                                        })
                                    }
                                    transactionResult={withdrawResult}
                                    isConnected={isConnected}
                                    isDisabled={isWithdrawalDisabled}
                                    isLoading={withdrawResult.isLoading}
                                    notice={getWithdrawalNotice()}
                                />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Stack>
            )}
        </Card>
    );
};
